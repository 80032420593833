import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Modules from './Modules';
import Storage from './Storage';
import Total from './Total';
import MainOutline from './MainOutline';
import Locate from './Locate';
import Customer from './Customer';
// import StorageOnly from './StorageOnly';
// import TotalStorageOnly from './TotalStorageOnly';
import TagManager from "react-gtm-module";
import { ModuleData } from './ModuleData';
import { StorageData } from './StorageData';
import { FinanceData } from './FinanceData';
import { LeaseData } from './LeaseData';
import Intercom from '@intercom/messenger-js-sdk';
import Design from './Design';
import useEstimate from '../../hooks/useEstimate';
import { solarData } from './SolarData';
import useHardware from '../../hooks/useHardware';
import useAutoDesigner from '../../hooks/useAutoDesigner';

const Build = ({
  status,
  setStatus
}) => {

  const initialFormState = {
    name: '',
    email: '',
    phone: '',
    address: '',
    state: '',
    zip: '',
    electricBill: '',
    systemSize: 0,
    subtotal: 0,
  };

  const initialResponseState = {
    estimateSize: 0,
    qcellAmount: 0,
    qcellSize: 0,
    qcellOffset: 0,
    qcellSubtotal: 0,
    solareverAmount: 0,
    solareverSize: 0,
    solareverOffset: 0,
    solareverSubtotal:0,
    solarProductionMonthly:0,
    projectId: '',
    designId: ''
  }

  const initialErrorFormState = {
    hasEmailAndIsValid: false,
    hasPhoneAndIsValid: false,
  }

  const initialTotalState = {
    cashPriceBeforeIncentives: 0,
    incentiveAmount: 0,
    totalPriceAfterIncentives: 0,
    financedMonthlyPayment: 0,
    leaseMonthlyPayment:0,
    financedTotal: 0,
    savingsAmount:0,
    financeOption:"",
    financeType:"",
    moduleMonthlyPayment:0,
    batteryMonthlyPayment:0
  }

  // const initialHardwareState = {
  //   solutions: [],
  //   module: solarData.modules.options[0],
  //   inverter: solarData.inverters.options[0],
  //   offset: 0,
  //   kWh: 0,
  //   unit: '',
  //   battery: solarData.batteries.options[0],
  //   batteryQuantity: 0,
  //   services: solarData.services.options[0],
  //   solarCost: 0,
  //   batteryCost: 0,
  //   serviceCost: 0,
  //   totalCost: 0,
  //   taxCredit: 0,
  //   totalAfterTaxCredit: 0,
  //   financedTotal: 0,
  //   financedMonthlyPayment: 0,
  //   selectedLender: Object.keys(FinanceData.Lenders[0])[0], // First lender name
  //   selectedProduct: FinanceData.Lenders[0][Object.keys(FinanceData.Lenders[0])[0]][0] // First product of first lender
  // }

  const financeData = FinanceData;
  
  const [formState, setFormState] = useState(initialFormState);
  const [responseState, setResponseState] = useState(initialResponseState);
  const [errorFormState, setErrorFormState] = useState(initialErrorFormState);
  const [section, setSection] = useState({ sectionNumber: 0 });
  const [batteryType, setBatteryType] = useState('tesla');
  const [selected, setSelected] = useState('qcell');
  const [systemSize, setSystemSize] = useState(0);
  const [offset, setOffset] = useState(0);
  const [moduleAmount, setModuleAmount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [batteryAmount, setBatteryAmount] = useState(0);
  const [batteryCost, setBatteryCost] = useState(0);
  const [subtotalWithBattery, setSubtotalWithBattery] = useState(0);
  const [total, setTotal] = useState(initialTotalState);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [batteryOnly, setBatteryOnly] = useState(false);
  const [selectedFinance, setSelectedFinance] = useState(financeData[0]);
  const [designLocked, setDesignLockedState] = useState(false);
  // const [satelliteImageUrl, setSatelliteImageUrl] = useState('');
  // const [hardware, setHardware] = useState(initialHardwareState);
  
  // Intercom({
  //   app_id: 'ym3nqthz',
  // });

  TagManager.initialize({'gtmId': "GTM-MD88S8R"});

  const { 
    hardware, 
    updateHardware, 
    updateLender, 
    updateProduct, 
    updateSolutions 
  } = useHardware();

  const { 
    getEstimate, 
    submitting,
    setSubmitting, 
    satelliteImageUrl, 
    loadingTextIndex, 
    loadingTexts, 
    customerErrMesg,
    setCustomerErrMesg,
  } = useEstimate(formState, responseState, setResponseState, setDataLoaded, updateHardware);

  const { designStatus, designInfo, startAutomation } = useAutoDesigner({
    hardware,
    updateHardware,
    responseState,
    formState,
    setDesignLocked: setDesignLockedState,
  });

  

  async function createCustomer() {
    try{
      setSubmitting(true);
      const data = JSON.stringify({
        "name": formState.name,
        "address": formState.address,
        "state": formState.state,
        "email": formState.email,
        "phone": formState.phone,
        "electricBill": formState.electricBill,
        "estimateSize": responseState.estimateSize,
        "solarProductionMonthly": responseState.solarProductionMonthly,
        "projectId": responseState.projectId,
        "designId":responseState.designId
      });
      
      const config = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/estimate',
        headers: { 
          'Content-Type': 'application/json', 
        },
        data : data
      };
      
      const response = await axios(config);
      const status = response.status;

      // if (status === 201) {
      //   await startAutomation();
      // }
      setSubmitting(false);
      return status;
    } catch(error) {
      console.error("Error creating customer:", error);
      setSubmitting(false);
      return error.response ? error.response.status : 500;
    }

  };

  async function updateCustomerQuoted(totalValues) {
    setSubmitting(true);
    // console.log("batt only ", batteryOnly);
    var data = JSON.stringify({
      "email": formState.email,
      "address":formState.address,
      "electricBill": formState.electricBill,
      "estimateSize": responseState.estimateSize,
      "solarProductionMonthly": responseState.solarProductionMonthly,
      "status": 'quoted',
      "moduleType": batteryOnly ? "" : selected,
      "moduleCost": batteryOnly ? 0 : subtotal,
      "systemSize": batteryOnly ? 0 : systemSize,
      "energyOffset": batteryOnly ? 0 : offset,
      "savings": batteryOnly ? 0 : totalValues.savingsAmount,
      "moduleAmount": moduleAmount,
      "batteryAmount": batteryAmount,
      "batteryType": batteryType,
      "batteryCost": batteryCost,
      "batteryOnly": batteryOnly,
      "cashPriceBeforeIncentives": totalValues.cashPriceBeforeIncentives,
      "incentiveAmount": totalValues.incentiveAmount,
      "totalPriceAfterIncentives": totalValues.totalPriceAfterIncentives,
      "financedMonthlyPayment": totalValues.financedMonthlyPayment,
      "leaseMonthlyPayment": totalValues.leaseMonthlyPayment,
      "financedTotal": totalValues.financedTotal,
      "financeOption": totalValues.financeOption,
      "financeType": totalValues.financeType,
      "moduleMonthlyPayment":batteryOnly ? "" : totalValues.moduleMonthlyPayment,
      "batteryMonthlyPayment":batteryAmount ? "" :totalValues.batteryMonthlyPayment
    });
    
    var config = {
      method: 'put',
      url: process.env.REACT_APP_API_URL + '/estimate',
      headers: { 
        'Content-Type': 'application/json', 
      },
      data : data
    };
    
    await axios(config)
    .then(function (response) {
    })
    .catch(function (error) {
    });
    
    
    setSubmitting(false);
  };

  const updateFormControl = (event: any) => {
    const { id, value } = event.target;
    const formKey = id;
    const updatedFormState = { ...formState };
    const updatedErrorFormState = {...errorFormState};
    updatedFormState[formKey] = value;

    switch(id) {
      case 'electricBill':
        // console.log("bill =",value);
        const bill = value.replace(/[^\d]/g, '');
        updatedFormState[formKey] = bill.slice(0,4);
        break;
      case 'email':
          if(value.length === 0){
            updatedErrorFormState.hasEmailAndIsValid = false;
            updatedFormState[formKey] = value;
            break;
          }
          if(!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            updatedErrorFormState.hasEmailAndIsValid = false;
            updatedFormState[formKey] = value;
            break;
          }
          else{
            updatedErrorFormState.hasEmailAndIsValid = true;
            updatedFormState[formKey] = value;
          }
          break;
      case 'phone':
          const formattedPhoneNumber = formatPhoneNumber(value);
          updatedFormState[formKey] = formattedPhoneNumber;
          if(formattedPhoneNumber.length !== 14){
            updatedErrorFormState.hasPhoneAndIsValid = false;
          }
          else{
            updatedErrorFormState.hasPhoneAndIsValid = true;
          }
          break;
      default:
          updatedFormState[formKey] = value;
          break;
          
  }

  function formatPhoneNumber(value: any) {
      // if input value is falsy eg if the user deletes the input, then just return
      if (!value) return value;
    
      // clean the input for any non-digit values.
      const phoneNumber = value.replace(/[^\d]/g, '');
    
      // phoneNumberLength is used to know when to apply our formatting for the phone number
      const phoneNumberLength = phoneNumber.length;
    
      // we need to return the value with no formatting if its less then four digits
      // this is to avoid weird behavior that occurs if you  format the area code to early
    
      if (phoneNumberLength < 4) return phoneNumber;
    
      // if phoneNumberLength is greater than 4 and less the 7 we start to return
      // the formatted number
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
    
      // finally, if the phoneNumberLength is greater then seven, we add the last
      // bit of formatting and return it.
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
    setErrorFormState(updatedErrorFormState);
    setFormState(updatedFormState);
  }

  const addressClick = () => {
    if(!status){
      setSelected(ModuleData[0].module);
      setBatteryOnly(false);
      setSystemSize(0);
      setOffset(0);
      setModuleAmount(0);
      setSubtotal(0);
      setResponseState(initialResponseState);
      setBatteryAmount(0);
      setBatteryCost(0);
      setSubtotalWithBattery(0);
      setBatteryType(StorageData[0].storage);
      const updatedSectionState = {...section}
      updatedSectionState['sectionNumber'] = 0;
      setSection(updatedSectionState);
    }
    
  }

  const nextButton = async () => {
    switch (section.sectionNumber) {
      case 0:
        await getEstimate();
        updateSection(1);
        break;
      case 1:
         
        await handleCreateCustomer();
        // setNextClicked(true);
        break;
      case 2:
        handleUpdateSubtotalWithBattery();
        break;
      case 3:
        handleUpdateCustomer(4);
        break;
      // case 5:
      //   handleUpdateCustomer(6);
      //   break;
      default:
        // Handle unexpected section numbers or do nothing
        break;
    }
  };
  
  const handleCreateCustomer = async () => {
    setShowLoadingIcon(true);
    setCustomerErrMesg('');
    
    try {
      // const commonCalculations = getCommonCalculations();
      // setTotal({ ...total, ...commonCalculations });
      const result = await createCustomer();
      console.log("create result", result);
      if (result === 422) {
        setCustomerErrMesg("Customer already registered, please log in");
      } 
      // else if (result===409) {
      //   setCustomerErrMesg("Customer already found");
      // }  
      else {
        updateSection(2);
        // handleCustomerCreated();
      }
    } catch (error) {
      console.log("Error", error);
      setCustomerErrMesg("Encountered Error");
      // Handle or log the error
    }
    setShowLoadingIcon(false);
  };
  
  const handleUpdateSubtotalWithBattery = () => {
    setSubtotalWithBattery(Number(subtotal) + Number(batteryCost));
    updateSection(3);
  };
  
  const handleUpdateCustomer = (nextSection) => {
    // console.log("selected finance ", selectedFinance);
    

    const commonCalculations = getCommonCalculations();
    // setSelectedFinance()
    // setSavings(commonCalculations.savingsAmount);
    setTotal({ ...total, ...commonCalculations });
    updateCustomerQuoted(commonCalculations);
    updateSection(nextSection);
  };
  
  const getCommonCalculations = () => {
    const subtotalNew = subtotalWithBattery;
    const cashPriceBeforeIncentives = subtotalNew;
    const incentiveAmount = subtotalNew * 0.3;
    const totalPriceAfterIncentives = subtotalNew - incentiveAmount;
    const financedTotal = totalPriceAfterIncentives / ((100 - selectedFinance.Fee)/100);
    const numberOfPayments = selectedFinance.Term * 12;
    const financeOption = selectedFinance.Option;
    const financeType = selectedFinance.Type;
    const financeRate = selectedFinance.APR / 100;
    let financedMonthlyPayment = 0;
    let leaseMonthlyPayment = 0;
    let moduleMonthlyPayment = 0;
    let batteryMonthlyPayment = 0;
    // console.log("fin type index page", selectedFinance.Type);
    const matchingRateData = LeaseData.find(data => data.State === formState.state);
    // console.log("matching rate ", matchingRateData);
    // if(selectedFinance.Type === 'Lease' && matchingRateData){
      // console.log("monthly production ", responseState.solarProductionMonthly);
      moduleMonthlyPayment = responseState.solarProductionMonthly * matchingRateData.Rate;
      batteryMonthlyPayment = batteryAmount * 56.87;
      leaseMonthlyPayment = moduleMonthlyPayment + batteryMonthlyPayment;
      // console.log("monthly payment lease ", financedMonthlyPayment);
    // }
    // else{
      financedMonthlyPayment = (financedTotal * (financeRate / 12)) / (1 - (1 + (financeRate / 12)) ** (-numberOfPayments));

    // }
    const savingsAmount = (((formState.electricBill * 12) * ((((1 + .034) ** 25) - 1) / .034)) - (subtotalWithBattery - (subtotalWithBattery * .3)));

    return {
      cashPriceBeforeIncentives,
      incentiveAmount,
      totalPriceAfterIncentives,
      financedMonthlyPayment,
      leaseMonthlyPayment,
      financedTotal,
      financeOption,
      financeType,
      savingsAmount,
      moduleMonthlyPayment,
      batteryMonthlyPayment,
    };
  };
  

  function updateSection(section) {
    const updatedSectionState = {...section}
    updatedSectionState['sectionNumber'] = section;
    setSection(updatedSectionState);
  }

  const backButton = () => {
    const updatedSectionState = {...section}
    updatedSectionState['sectionNumber'] = section.sectionNumber - 1;
    setSection(updatedSectionState);
  }

  // useEffect(()=>{
  //   TagManager.initialize({gtmId: 'GTM-MD88S8R'})
  // },[])

  // const updateHardware = useCallback((field, value) => {
  //   setHardware((prevState) => ({
  //     ...prevState,
  //     [field]: typeof value === 'function' ? value(prevState[field]) : value,
  //   }));
  // }, []);

  // useEffect(() => {
  //   if (dataLoaded && nextClicked) {
  //       handleCreateCustomer();
  //   }
  // }, []);

  return (
    <>
    <MainOutline backgroundImage={satelliteImageUrl}>
      {!status &&
        <>
          {section.sectionNumber===0 &&
            <Locate
              formState={formState}
              setFormState={setFormState}
              initialFormState={initialFormState}
              section={section}
              setSection={setSection}
              nextButton={nextButton}
              updateFormControl={updateFormControl}
              submitting={submitting}
              customerErrMesg={customerErrMesg}
              setCustomerErrMesg={setCustomerErrMesg}
              loadingTexts={loadingTexts}
              loadingTextIndex={loadingTextIndex}
              hardware={hardware}
              updateHardware={updateHardware}
              updateSolutions={updateSolutions}
            />
          }
          {section.sectionNumber===1 &&
            <Customer 
              addressClick={addressClick}
              formState={formState}
              updateFormControl={updateFormControl}
              errorFormState={errorFormState}
              nextButton={nextButton}
              nextClicked={nextClicked}
              dataLoaded={dataLoaded}
              submitting={submitting}
              customerErrMesg={customerErrMesg}
              batteryOnly={batteryOnly}
              setBatteryOnly={setBatteryOnly}
              backButton={backButton}
            />
          }
          {section.sectionNumber===2 &&
            <Design
              addressClick={addressClick}
              formState={formState}
              responseState={responseState}
              moduleAmount={moduleAmount} 
              systemSize={systemSize}
              total={total}
              offset={offset}
              selected={selected}
              subtotal={subtotal}
              nextButton={nextButton}
              setSelected={setSelected}
              setSystemSize={setSystemSize}
              setOffset={setOffset}
              setModuleAmount={setModuleAmount}
              batteryType={batteryType}
              setBatteryType={setBatteryType}
              setSubtotal={setSubtotal}
              batteryCost={batteryCost}
              backButton={backButton}
              satelliteImageUrl={satelliteImageUrl}
              updateHardware={updateHardware}
              hardware={hardware}
              updateLender={updateLender}
              updateProduct={updateProduct}
              updateSolutions={updateSolutions}
            />
          }
          {section.sectionNumber===3 &&
            <Modules
              addressClick={addressClick}
              formState={formState}
              responseState={responseState}
              moduleAmount={moduleAmount} 
              systemSize={systemSize}
              total={total}
              offset={offset}
              selected={selected}
              subtotal={subtotal}
              nextButton={nextButton}
              setSelected={setSelected}
              setSystemSize={setSystemSize}
              setOffset={setOffset}
              setModuleAmount={setModuleAmount}
              setSubtotal={setSubtotal}
              batteryCost={batteryCost}
            />
          }
          {section.sectionNumber===4 &&
            <Storage
              addressClick={addressClick}
              formState={formState}
              batteryAmount={batteryAmount}
              systemSize={systemSize}
              total={total}
              setTotal={setTotal}
              offset={offset}
              batteryType={batteryType}
              batteryCost={batteryCost}
              subtotal={subtotal}
              subtotalWithBattery={subtotalWithBattery}
              backButton={backButton}
              nextButton={nextButton}
              setBatteryAmount={setBatteryAmount}
              setBatteryCost={setBatteryCost}
              setSubtotalWithBattery={setSubtotalWithBattery}
              setBatteryType={setBatteryType}
              submitting={submitting}
              batteryOnly={batteryOnly}
            />
          }
          {section.sectionNumber===5 &&
            <Total
              addressClick={addressClick}
              address={formState.address}
              state={formState.state}
              email={formState.email}
              electricBill={formState.electricBill}
              systemSize={systemSize}
              solarProductionMonthly={responseState.solarProductionMonthly}
              offset={offset}
              moduleCost={subtotal}
              total={total}
              setTotal={setTotal}
              subtotalWithBattery={subtotalWithBattery}
              moduleAmount={moduleAmount}
              moduleType={selected}
              batteryAmount={batteryAmount}
              batteryCost={batteryCost}
              batteryType={batteryType}
              backButton={backButton}
              status={status}
              setStatus={setStatus}
              selectedFinance={selectedFinance}
              setSelectedFinance={setSelectedFinance}
              batteryOnly={batteryOnly}
            />
          }
        </>
      }
      {/* {status &&
        <Total
          addressClick={addressClick}
          address={formState.address}
          state={formState.state}
          email={formState.email}
          systemSize={systemSize}
          solarProductionMonthly={responseState.solarProductionMonthly}
          offset={offset}
          moduleCost={subtotal}
          total={total}
          setTotal={setTotal}
          subtotalWithBattery={subtotalWithBattery}
          moduleAmount={moduleAmount}
          moduleType={selected}
          batteryAmount={batteryAmount}
          batteryCost={batteryCost}
          batteryType={batteryType}
          backButton={backButton}
          status={status}
          selectedFinance={selectedFinance}
          setSelectedFinance={setSelectedFinance}
          batteryOnly={batteryOnly}
        />
      } */}
    </MainOutline>
    </>
  );

}

export default Build
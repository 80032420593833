import React, { useState, useEffect, useRef } from 'react';
import { AddressUrl, Arrow, BackButton, ImageBg, QuoteBg, QuoteGrid, QuoteHeader, QuoteInnerWrapper, ScrollAnimationWrapper } from './QuoteElements';
import lunex from '../../images/LunexPower.webp';
import CustomAccordion from './Accordion';
import HardwareDetails from './HardwareDetails';
import styled from 'styled-components';
import { solarData } from './SolarData';
import { ToggleButton, ToggleButtonGroup, Typography, Button, Slider, Box, CircularProgress, } from '@mui/material';
import SolarProductionOffsetSlider from './OffsetSlider';
import BottomBar from './BottomBar';
import PricingPopup from './PricingPopup';
import { LeaseData } from './LeaseData';
import useIsMobile from "../../hooks/useIsMobile";
import ScrollAnimation from './ScrollAnimation';
import useAutoDesigner from '../../hooks/useAutoDesigner';

const HomeImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
  display: block;
  margin: 0 auto; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  /* padding-top: 10px; */
  /* position: sticky; */
  top:0;
  z-index: 1;
  background-color: white;
  
  /* gap: 5px; */
  /* justify-content: space-around; */
  
  /* overflow: hidden; */
  /* align-items: center; */
  /* padding-bottom: 10px; */
  
`;

const ImageInternalWrapper = styled.div`
  /* position: relative; */
  /* width: 100%; */
  height: 200px;
  overflow: hidden;
  border-radius: 12px;
  /* object-fit: cover;
  object-position: center;
  display: block;
  margin: 0 auto;
  overflow: hidden; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
`;

const ImageText = styled.div`
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -200%); 
  color: white;
  font-size: 1rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CustomContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  display: ${({confirmed}) => (confirmed ? 'none' : 'auto')};
`;
const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
`;

const TopContainerWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }

`

const FinanceFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;

  @media screen and (max-width: 900px) {
    /* padding: 5px; */
    /* margin: 15px; */
    flex-direction: column;
    /* align-items: center; */
  }
`;

export const TopContainer = styled.div`
  width: 90%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({bottombar}) => (bottombar ? '0' : '20px')};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Value = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
`;

export const Title = styled.div`
  font-size: 0.8rem;
  font-weight: normal;
  color: #555;
`;

const QuoteHeaderCustom = styled.h1`
  /* margin-top: 150px; */
  text-align: center;
  font-size: 1.6rem;
  color: #333 !important;
  display: ${({confirmed}) => (confirmed ? 'none' : 'auto')};
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

const GridWrapper = styled.div`
  /* position: relative; */
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled.div`
  font-size: 0.7rem;
  color: grey !important;
  margin: 10px 0;
  text-align: center;
`

export const StyledTypography = styled(Typography)`
  font-size: ${({header}) => (header ? '1.2rem !important' : '0.8rem !important')};
  color: ${({header}) => (header ? '#1976d2' : 'black')};
  font-weight: ${({header}) => (header ? 'bold !important' : 'normal')};
  margin: 5px 0;
  text-align: ${(props) => props.align || 'left'};
  text-decoration: ${({strike}) => (strike ? 'line-through' : 'none')};
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 0.7rem !important;
  /* margin: 5px 0; */
  text-align: 'left';
  color: #777;
`;

const StyledButton = styled(Button)`
  width: 100%;
  /* padding-top: 50px !important; */
  margin-top: 50px !important;
  background-color: ${(props) => (props.color === 'primary' ? '#1976d2' : '#d32f2f')} !important;
  color: white;
  &:hover {
    background-color: ${(props) => (props.color === 'primary' ? '#1565c0' : '#c62828')} !important;
  }
`;

export const TextFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  /* margin: 20px 0; */
`;

const ButtonWrapper = styled.div`
  margin: 20px;
  display: flex;
  justify-content: center;
`

const StyledButton2 = styled(Button)`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  /* display: flex;
  align-items: center; */
  margin-top: 20px;
  background-color: #1976d2 !important;
  color: white !important;

  &:hover {
    background-color: #1565c0 !important;
  }
`;

const TotalHeader = styled(Typography)`
  margin-top: 20px !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
`;

const SliderHeader = styled(Typography)`
  font-size: 0.9rem !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
`;

const SliderContainerBattery = styled(Box)`
  width: 95%;
  /* margin: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
`;
const SliderContainerServices = styled(Box)`
  width: 60%;
  /* margin: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
`;

const CustomSlider = styled(Slider)`
  width: 100%;
  margin-top: 10px;

  .MuiSlider-mark {
    background-color: white;
    width: 25px;
    height: 25px;
    border: 4px solid #1976d2;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);

    &:hover {
      width: 30px; /* Increase the outer circle size on hover */
      height: 30px; /* Increase the outer circle size on hover */
    }
  }

  .MuiSlider-markActive {
    background-color: #1976d2;
    opacity: 1;
  }

  .MuiSlider-thumb {
    background-color: #1976d2; 
    width: 22px;
    height: 22px;
  }

  .MuiSlider-track {
    background-color: #1976d2;
  }

  .MuiSlider-rail {
    background-color: #1976d2; 
    opacity: 0.3; 
  }

  .MuiSlider-markLabel {
    font-size: 1.2rem; 
    font-weight: bold;
  }


`;

const SubmitMessageWrapper = styled.div`
    width: 90%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {
        width: 80%;
        margin: 0 auto;
    }

`

const SubmitMessageText = styled.div`
  text-align: center;
  color: ${({success}) => (success ? '#388e3c' : '#d32f2f')};
  font-size: 1.5rem;
  margin-top: 0.25rem;
`

const Design = ({
  addressClick,
  formState,
  responseState,
  nextButton,
  setSystemSize,
  setOffset,
  backButton,
  satelliteImageUrl,
  hardware, 
  updateHardware, 
  updateLender, 
  updateProduct,
  updateSolutions
}) => {
  const [view, setView] = useState('Modules');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [designLocked, setDesignLocked] = useState(false);
  const scrollContainerRef = useRef(null);
  const isMobile = useIsMobile();
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [design, setDesign] = useState(false);

  const { designStatus, startAutomation, stopPolling } = useAutoDesigner({
    hardware,
    updateHardware,
    responseState,
    formState,
    setDesignLocked
  })

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleBarClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleAddSolar = () => {
    updateHardware('systemSize', responseState.estimateSize);
    updateSolutions(1);
  };
  const handleAddBattery = () => {
    updateHardware('batteryQuantity', hardware.recommendedBattQuantity);
    updateSolutions(2);
  };

  const handleAddCharger = () => {
    updateHardware('serviceQuantity', 1);
    updateSolutions(3);
    // updateHardware('serviceCost', hardware.services.price);
  };

  const handleRemoveSolar = () => {
    updateHardware('systemSize', 0);
    updateHardware('offset', 0);
    // updateHardware('solarCost', 0);
    updateSolutions(1);
  };
  const handleRemoveBattery = () => {
    if (hardware.inverter.name === 'Tesla Powerwall 3') {
      updateHardware('inverter', solarData.inverters.options[0]); // Reset to default inverter
    }
    updateHardware('batteryQuantity', 0);
    updateSolutions(2);
    // updateHardware('batteryCost', 0);
  };

  const handleRemoveCharger = () => {
    updateHardware('serviceQuantity', 0);
    updateSolutions(3);
    // updateHardware('serviceCost', 0);
  };



  const handleSliderChangeBattery = (event, newValue) => {
    if (newValue > 0) {
      updateHardware('batteryQuantity', newValue);
    }
    else {
      updateHardware('batteryQuantity', 0);
    }
  };

  const handleSliderChangeServices = (event, newValue) => {
    if (newValue > 0) {
      updateHardware('serviceQuantity', newValue);
      updateHardware('serviceCost', newValue * hardware.services.price);
    } else {
      updateHardware('serviceQuantity', 0);
      updateHardware('serviceCost', 0);
    }
  };

  const handleInverterChange = (newInverter) => {
    // console.log("newInverter", newInverter);
    // console.log("batt quant", hardware.batteryQuantity);
    // console.log("solutions", hardware.solutions);
    updateHardware('inverter', newInverter);

    // if (newInverter.name === 'Tesla Powerwall 3' && hardware.systemSize > 0) {
    //   updateHardware('battery', solarData.batteries.options[0]);
    //   updateHardware('batteryQuantity', hardware.recommendedBattQuantity);
    //   updateSolutions(2);
    // }
    if (newInverter.name === 'Tesla Powerwall 3' && hardware.battery.name !== 'Tesla Powerwall 3') {
      updateHardware('battery', solarData.batteries.options[0]);
      if(hardware.batteryQuantity === 0){
        updateHardware('batteryQuantity', hardware.recommendedBattQuantity);
        updateSolutions(2);
      } 
    }
    if (newInverter.name === 'Tesla Powerwall 3' && hardware.battery.name === 'Tesla Powerwall 3') {
      if(hardware.batteryQuantity === 0){
        updateHardware('batteryQuantity', hardware.recommendedBattQuantity);
        updateSolutions(2);
      } 
    }
    // console.log("batt after", hardware.battery.name);
    // console.log("batt quant after", hardware.batteryQuantity);
    // console.log("solutions after", hardware.solutions);
  };

  const handleBatteryChange = (newBattery) => {
    updateHardware('battery', newBattery);
    if (hardware.inverter.name === 'Tesla Powerwall 3' && newBattery.name !== 'Tesla Powerwall 3') {
      updateHardware('inverter', solarData.inverters.options[0]); 
    }
    // updateHardware('batteryQuantity', hardware.recommendedBattQuantity);
  };

  const serviceMarks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
  ];

  const batteryMarks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
  ];

  function valuetext(value: number) {
    return `${value}`;
  }

  const addressClickDesign = () => {
    addressClick();
    stopPolling();
  }

  const backButtonDesign = () => {
    backButton();
    stopPolling();
  }

  async function handleSignUp() {
    setSubmitting(true);
    setError(false);

    const data = JSON.stringify({
      "projectId": responseState.projectId,
      "hardware":hardware,
    });
    
    const config = {
      method: 'put',
      headers: { 
        'Content-Type': 'application/json', 
      },
      body: data
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate`, config);
  
    if (response.ok) {
      setConfirmed(true);
      setSuccess('Thank you! You will receive an email shortly!');
    } else {
      setError('Sign Up failed');
    }
    
    setSubmitting(false);
  }

  useEffect(() => {

    console.log("downPayment updated in Design:", hardware.downPayment);
    // Helper function to calculate solar cost
    const calculateSolarCost = (moduleOption, inverterOption) => {
      const ppw = formState.state === 'FL' ? moduleOption.ppw : moduleOption.ppwNE;
      const solarPPW = ppw + (inverterOption.ppw || 0);
      const solarCost = solarPPW * hardware.systemSize;
      return {solarCost, solarPPW};
    };
  
    // Helper function to calculate battery costs
    const calculateBatteryCosts = (batteryOption, inverterOption, moduleOption) => {
      let batteryCost;
      if (inverterOption.name === 'Tesla Inverter' && hardware.systemSize > 0 && batteryOption.name === 'Tesla Powerwall 3' && hardware.batteryQuantity > 0) {
        batteryCost = batteryOption.inverterPrice * hardware.batteryQuantity;
      }
      else{
        const initialBatteryCost = hardware.batteryQuantity > 0 ? batteryOption.firstUnit : 0;
        const additionalBatteryCost = hardware.batteryQuantity > 1 ? (batteryOption.additionalUnits * (hardware.batteryQuantity - 1)) : 0;
        // const margin = 1.37;
        
        batteryCost = initialBatteryCost + additionalBatteryCost;
      }
  
      return {batteryCost};
    };
  
    // Helper function to calculate service costs
    const calculateServiceCost = (serviceOption) => {
      return hardware.serviceQuantity * serviceOption.price;
    };

    const calculateSolarFinance = (systemPrice, monthlyProduction, apr, termYears, feePercentage, downPayment, taxCreditPercentage) => {
      // Convert inputs
      const months = termYears * 12;
      const monthlyRate = apr / 100 / 12; 
      const taxCreditRate = taxCreditPercentage / 100; 
      const feeRate = feePercentage / 100;
  
      // 1. Calculate the financed amount before the tax credit
      const financedAmountPreTaxCredit = systemPrice - downPayment;

      console.log("financedAmountPreTaxCredit",financedAmountPreTaxCredit);
  
      // 2. Calculate the tax credit
      const taxCreditAmount = financedAmountPreTaxCredit * taxCreditRate;

      console.log("taxCreditAmount",taxCreditAmount);
  
      // 3. Calculate the financed amount after applying the tax credit
      const financedAmountPostTaxCredit = financedAmountPreTaxCredit - taxCreditAmount;

      console.log("financedAmountPostTaxCredit", financedAmountPostTaxCredit);
  
      // 4. Calculate the total cost with the fee applied
      const totalCostWithFee = financedAmountPostTaxCredit * (1 + feeRate);

      console.log("totalCostWithFee", totalCostWithFee);
  
      // 5. Calculate the monthly payment pre-tax credit
      const monthlyPaymentPreTaxCredit = financedAmountPreTaxCredit * (monthlyRate * Math.pow(1 + monthlyRate, months)) / (Math.pow(1 + monthlyRate, months) - 1);

      console.log("monthlyPaymentPreTaxCredit", monthlyPaymentPreTaxCredit);
  
      // 6. Calculate the monthly payment post-tax credit
      const monthlyPaymentPostTaxCredit = totalCostWithFee * (monthlyRate * Math.pow(1 + monthlyRate, months)) / (Math.pow(1 + monthlyRate, months) - 1);

      console.log("monthlyPaymentPostTaxCredit",monthlyPaymentPostTaxCredit);
  
      return {
          financedAmountPreTaxCredit: financedAmountPreTaxCredit.toFixed(2),
          monthlyPaymentPreTaxCredit: monthlyPaymentPreTaxCredit.toFixed(2),
          monthlyPaymentPostTaxCredit: monthlyPaymentPostTaxCredit.toFixed(2)
      };
    }
      
      // Example usage
      // const systemPrice = 50000; // Example initial system price in USD
      // const monthlyProduction = 1500; // Example monthly solar production in kWh
      // const apr = 3.99; // Example APR in percentage
      // const termYears = 25; // Term in years
      // const feePercentage = 32.4; // Example fee in percentage
      // const downPayment = 10000; // Example down payment in USD
      // const taxCreditPercentage = 30; // Example tax credit percentage
      
      // const result = calculateSolarFinance(systemPrice, monthlyProduction, apr, termYears, feePercentage, downPayment, taxCreditPercentage);
      // console.log('Total Financed Amount Pre-Tax Credit: $' + result.financedAmountPreTaxCredit);
      // console.log('Monthly Payment Pre-Tax Credit: $' + result.monthlyPaymentPreTaxCredit);
      // console.log('Monthly Payment Post-Tax Credit: $' + result.monthlyPaymentPostTaxCredit);
  
  
    // Helper function to calculate financials
    const calculateFinancials = (totalCost, totalAfterTaxCredit, downPayment) => {
      console.log("down pay", downPayment);
      let financedMonthlyAmountPreTaxCredit =0, financedTotal = 0, financedMonthlyAmountAfterTaxCredit = 0;

      let ppa = 0;
      if (hardware.selectedProduct.type === 'Loan') {
        financedMonthlyAmountPreTaxCredit = ((totalCost - downPayment) * ((hardware.selectedProduct.apr / 100) / 12)) / (1 - (1 + ((hardware.selectedProduct.apr / 100) / 12)) ** (-(hardware.selectedProduct.term * 12)));
        financedTotal = ((totalAfterTaxCredit / ((100 - hardware.selectedProduct.fee) / 100)) - downPayment).toFixed(2);
        console.log("fin total", financedTotal);
        financedMonthlyAmountAfterTaxCredit = (financedTotal * ((hardware.selectedProduct.apr / 100) / 12)) / (1 - (1 + ((hardware.selectedProduct.apr / 100) / 12)) ** (-(hardware.selectedProduct.term * 12)));
        console.log("fin mo pay", financedMonthlyAmountAfterTaxCredit);
      } else if (hardware.selectedProduct.type === 'Lease') {
        const matchingRateData = LeaseData.find(data => data.State === formState.state);
        // financedTotal = (totalAfterTaxCredit / ((100 - hardware.selectedProduct.fee) / 100)).toFixed(2);

        financedTotal = (totalAfterTaxCredit / ((100 - hardware.selectedProduct.fee) / 100)).toFixed(2);
        // financedMonthlyAmount = responseState.solarProductionMonthly * matchingRateData.Rate;

        const totalCostWithFee = (responseState.solarProductionMonthly*12)*matchingRateData.Rate*hardware.selectedProduct.term;
        const rate = ((totalCostWithFee)+(totalCostWithFee * (hardware.selectedProduct.fee/100)))/((responseState.solarProductionMonthly*12)*hardware.selectedProduct.term);
        financedMonthlyAmountAfterTaxCredit = rate * hardware.systemSize;

        // console.log("fin monthly pre tax", financedMonthlyAmountPreTaxCredit);
        // console.log("fin monthly after tax", financedMonthlyAmountAfterTaxCredit);
        // console.log("rate", rate);
        // console.log("total cost with fee", totalCostWithFee);

      }
      return { financedTotal, financedMonthlyAmountPreTaxCredit, financedMonthlyAmountAfterTaxCredit };
    };
  
    // Helper function to calculate backup hours and recommended battery quantity
    const calculateBackupInfo = (batteryOption, batteryCapacity) => {
      // console.log("battery", batteryOption, batteryCapacity)
      // console.log("solar production", responseState.solarProductionMonthly)
      const backupHours = Math.round((batteryCapacity * 0.8) / ((((responseState.solarProductionMonthly * 12) / 365) / 24) * 0.75));
      const minBatteryCapacity = 12 * (((responseState.solarProductionMonthly * 12) / 365 / 24) * 0.75) / 0.8;
      const recommendedBattQuantity = Math.min(Math.ceil(minBatteryCapacity / batteryOption.capacity), 8);
  
      // console.log("backup hours, rec batt quant", backupHours, recommendedBattQuantity);
      return { backupHours, recommendedBattQuantity };
    };
  
    // Main calculation logic
    const calculateCosts = () => {
      const moduleOption = solarData.modules.options.find(option => option.name === hardware.module.name);
      const inverterOption = solarData.inverters.options.find(option => option.name === hardware.inverter.name);
      const batteryOption = solarData.batteries.options.find(option => option.name === hardware.battery.name);
      const serviceOption = solarData.services.options.find(option => option.name === hardware.services.name);

      // console.log("batt option", batteryOption);
  
      const {solarCost, solarPPW} = calculateSolarCost(moduleOption, inverterOption);
      const {batteryCost} = calculateBatteryCosts(batteryOption, inverterOption, moduleOption);
      const batteryCapacity = hardware.batteryQuantity * batteryOption.capacity;
      const serviceCost = calculateServiceCost(serviceOption);
  
      const { backupHours, recommendedBattQuantity } = calculateBackupInfo(batteryOption, batteryCapacity);
      const totalCost = solarCost + batteryCost + serviceCost;
      const taxCredit = totalCost * 0.3;
      const totalAfterTaxCredit = totalCost - taxCredit;
  
      console.log("downPayment updated in Design:", hardware.downPayment);
      const { financedTotal, financedMonthlyAmountPreTaxCredit, financedMonthlyAmountAfterTaxCredit } = calculateFinancials(totalCost, totalAfterTaxCredit, hardware.downPayment);
  
      return {
        solar: solarCost,
        solarPPW: solarPPW,
        battery: batteryCost,
        // powerwallCost,
        backupHours,
        recommendedBattQuantity,
        batteryCapacity,
        service: serviceCost,
        total: totalCost,
        taxCredit,
        totalAfterTaxCredit,
        financedTotal,
        financedMonthlyAmountPreTaxCredit,
        financedMonthlyAmountAfterTaxCredit
      };
    };
  
    // Call calculateCosts and destructure the results
    const {
      solar,
      solarPPW,
      battery,
      // powerwallCost,
      backupHours,
      recommendedBattQuantity,
      batteryCapacity,
      service,
      total,
      taxCredit,
      totalAfterTaxCredit,
      financedTotal,
      financedMonthlyAmountPreTaxCredit,
      financedMonthlyAmountAfterTaxCredit
    } = calculateCosts();
  
    // Update hardware state
    updateHardware('solarCost', solar);
    updateHardware('solarPPW', solarPPW);
    updateHardware('batteryCost', battery);
    // updateHardware('powerwallCost', powerwallCost);
    // updateHardware('additionalPowerwallCost', additionalPowerwall);
    updateHardware('backupHours', backupHours);
    updateHardware('recommendedBattQuantity', recommendedBattQuantity);
    updateHardware('batteryCapacity', batteryCapacity);
    updateHardware('serviceCost', service);
    updateHardware('totalCost', total);
    updateHardware('taxCredit', taxCredit);
    updateHardware('totalAfterTaxCredit', totalAfterTaxCredit);
    updateHardware('financedTotal', financedTotal);
    updateHardware('financedMonthlyPaymentPreTaxCredit', financedMonthlyAmountPreTaxCredit);
    updateHardware('financedMonthlyPaymentAfterTaxCredit', financedMonthlyAmountAfterTaxCredit);
    
    // Check and update solution states
    if (hardware.solutions.includes(1) && hardware.systemSize === 0) {
      updateHardware('systemSize', responseState.estimateSize);
    }
    if (hardware.solutions.includes(2) && hardware.batteryQuantity === 0) {
      // console.log("got insideeee");
      updateHardware('batteryQuantity', hardware.recommendedBattQuantity);
    }
    if (hardware.solutions.includes(3) && hardware.serviceQuantity === 0) {
      updateHardware('serviceQuantity', 1);
    }

    if (hardware.solutions.includes(1) && hardware.systemSize === 0 && hardware.solutions.includes(2) && hardware.batteryQuantity === 0){
      updateHardware('inverter', solarData.inverters.options[0])
    }
  
  }, [
    hardware.systemSize,
    hardware.module,
    hardware.inverter,
    hardware.battery,
    hardware.batteryQuantity,
    hardware.recommendedBattQuantity,
    hardware.additionalPowerwallCost,
    hardware.solarCost,
    hardware.batteryCost,
    hardware.serviceCost,
    hardware.services,
    hardware.selectedProduct,
    hardware.totalAfterTaxCredit,
    hardware.total,
    updateHardware,
    formState.state,
    responseState.solarProductionMonthly,
    hardware.serviceQuantity,
    hardware.solutions,
    responseState.estimateSize,
    hardware.downPayment
  ]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <QuoteGrid>
        {!isPopupOpen &&
          <ScrollAnimationWrapper design>
            {confirmed ?
              <ScrollAnimation 
                scrollContainerRef={scrollContainerRef}
              />
            :
              <ScrollAnimation 
                scrollContainerRef={scrollContainerRef}
                useScroll
              />
            }
            
          </ScrollAnimationWrapper>
        }
        {isPopupOpen && (
          <PricingPopup
            systemSize={hardware.systemSize}
            hardware={hardware}
            updateHardware={updateHardware}
            updateLender={updateLender}
            updateProduct={updateProduct}
            onClick={handleClosePopup}
            responseState={responseState}
            formState={formState}
            satelliteImageUrl={satelliteImageUrl}
            setDesignLocked={setDesignLocked}
            designStatus={designStatus}
          />
        )}
        {hardware.systemSize > 0 &&
          <BottomBar
            systemSize={hardware.systemSize}
            hardware={hardware}
            onClick={handleBarClick}
            formState={formState}
            responseState={responseState}
            updateHardware={updateHardware}
            designLocked={designLocked}
            setDesignLocked={setDesignLocked}
            designStatus={designStatus}
            startAutomation={startAutomation}
          />
        }
        
      {(!isMobile || !isPopupOpen) &&
      <QuoteInnerWrapper ref={scrollContainerRef} ispopupopen={isPopupOpen} design={hardware.systemSize > 0 ? true : undefined}>
        <BackButton onClick={backButtonDesign}>
            <Arrow>&lt;</Arrow>
            Back
          </BackButton>
        {/* <QuoteHeader>Your Home</QuoteHeader> */}
        <ImageWrapper>
          
          <ImageInternalWrapper>
            {hardware.designImage && <HomeImg src={hardware.designImage} alt="Satellite view of the home" />}
          </ImageInternalWrapper>
          <AddressUrl onClick={addressClickDesign} disabled>{formState.address}</AddressUrl>
        </ImageWrapper>
        
        <QuoteHeaderCustom confirmed={confirmed}>Design Your System</QuoteHeaderCustom>
        
        
        <CustomContainer confirmed={confirmed}>
          <CustomAccordion
            headerTitle="Solar Power Options"
            title={hardware.systemSize !== 0 && hardware.solutions.includes(1) ? hardware.module.name + " | " + hardware.inverter.name : 'Would you like to add Solar?' }
            amount={hardware.systemSize !== 0 && hardware.solutions.includes(1) ? "$" + Math.round(hardware.solarCost).toLocaleString() : ''}
            open={hardware.solutions.includes(1)}
          >
            
            <ToggleContainer>
              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleViewChange}
                aria-label="View Selection"
              >
                <ToggleButton value="Modules" aria-label="Modules">
                  Modules
                </ToggleButton>
                <ToggleButton value="Inverters" aria-label="Inverters">
                  Inverters
                </ToggleButton>
              </ToggleButtonGroup>
            </ToggleContainer>

            {view === 'Modules' ? (
              <>
                <HardwareDetails
                  title={solarData.modules.title}
                  options={solarData.modules.options}
                  selectedOption={hardware.module}
                  setSelectedOption={(option) => updateHardware('module', option)}
                />
              </>
            ) : (
              <HardwareDetails
                title={solarData.inverters.title}
                options={solarData.inverters.options}
                selectedOption={hardware.inverter}
                setSelectedOption={handleInverterChange}
              />
            )}
            <TotalHeader>Pricing</TotalHeader>
            <TextFieldsWrapper>
              <StyledTypography align="left" variant="body1">
                {hardware.module.name} | {hardware.inverter.name}
              </StyledTypography>
              <StyledTypography align="right" variant="body1">
                ${hardware.solarPPW} / Watt
              </StyledTypography>
            </TextFieldsWrapper>
            {hardware.systemSize === 0 &&
              <StyledButton variant="contained" color="primary" onClick={handleAddSolar}>
                Add Solar
              </StyledButton>
            }
            {hardware.systemSize !== 0 &&
              <>
              <SolarProductionOffsetSlider
                estimateSize={responseState.estimateSize}
                hardware={hardware}
                updateHardware={updateHardware}
                solarProductionMonthly={responseState.solarProductionMonthly}
              />
                <TotalHeader>Solar Total</TotalHeader>
                <TextFieldsWrapper>
                  <StyledTypography align="left" variant="body1">
                    {hardware.module.name} | {hardware.inverter.name}
                  </StyledTypography>
                  <StyledTypography align="right" variant="body1" strike>
                    ${Math.round(hardware.solarCost).toLocaleString()}
                  </StyledTypography>
                </TextFieldsWrapper>
                <StyledSubtitle align="left">
                  Including Installation
                </StyledSubtitle>
                <br />
                <TextFieldsWrapper>
                  <StyledTypography header>
                    With Incentives
                  </StyledTypography>
                  <StyledTypography header>
                    ${Math.round(hardware.solarCost - (hardware.solarCost*0.3)).toLocaleString()}
                  </StyledTypography>
                </TextFieldsWrapper>
                {/* <StyledButton variant="contained" color="secondary" onClick={handleRemoveSolar}>
                  Remove Solar
                </StyledButton> */}
              </>
            }
            
          </CustomAccordion>
          <br />
          <CustomAccordion
            headerTitle="Battery Storage Solutions"
            title={hardware.batteryCost !== 0 ? hardware.battery.name : "Would you like to add a battery?"}
            amount={hardware.batteryCost !== 0 ? "$" + Math.round(hardware.batteryCost).toLocaleString() : ""}
            open={hardware.solutions.includes(2)}
          >
            <HardwareDetails
              title={solarData.batteries.title}
              options={solarData.batteries.options}
              selectedOption={hardware.battery}
              setSelectedOption={handleBatteryChange}
            />
            <TotalHeader>Pricing</TotalHeader>
            <TextFieldsWrapper>
              <StyledTypography align="left" variant="body1">
                {hardware.battery.name}
              </StyledTypography>
              <StyledTypography align="right" variant="body1">
                ${hardware.inverter.name === 'Tesla Inverter' && hardware.systemSize > 0 ? hardware.battery.inverterPrice.toLocaleString() : hardware.battery.firstUnit.toLocaleString()}
              </StyledTypography>
            </TextFieldsWrapper>
            {!(hardware.inverter.name === 'Tesla Inverter' && hardware.systemSize > 0) &&
              <TextFieldsWrapper>
              <StyledTypography align="left" variant="body1">
                Additional Units
              </StyledTypography>
              <StyledTypography align="right" variant="body1">
                ${hardware.inverter.name === 'Tesla Powerwall 3' && hardware.systemSize > 0 ? hardware.additionalPowerwallCost.toLocaleString() : hardware.battery.additionalUnits.toLocaleString()}
              </StyledTypography>
            </TextFieldsWrapper>
            }
            
            {hardware.batteryQuantity === 0 &&
              <StyledButton variant="contained" color="primary" onClick={handleAddBattery}>
                Add Battery
              </StyledButton>
            }
            {hardware.batteryQuantity !== 0 &&(
              <>
                <SliderWrapper>
                  <TopContainer>
                    <Column>
                      <Value>{hardware.batteryCapacity} kWh</Value>
                      <Title>Battery Capacity</Title>
                    </Column>
                    <Column>
                      <Value>{hardware.backupHours} hours+</Value>
                      <Title>Backup at 75% Usage</Title>
                      <Title>With 20% Reserve</Title>
                    </Column>
                  </TopContainer>
                  <SliderContainerBattery>
                    <SliderHeader>
                      Number of Batteries
                    </SliderHeader>
                    <CustomSlider
                      value={hardware.batteryQuantity}
                      onChange={handleSliderChangeBattery}
                      aria-label="Batteries"
                      getAriaValueText={valuetext}
                      valueLabelDisplay="auto"
                      step={1}
                      marks={batteryMarks}
                      min={1}
                      max={8}
                    />
                  </SliderContainerBattery>
                  <TotalHeader>{hardware.recommendedBattQuantity} Batter{hardware.recommendedBattQuantity === 1 ? 'y' : "ies"} Recommended</TotalHeader>
                  <StyledSubtitle>
                    We recommend at least 12 hours of backup at 75% usage
                  </StyledSubtitle>
                </SliderWrapper>            
                <TotalHeader>Battery Total</TotalHeader>
                <TextFieldsWrapper>
                  <StyledTypography align="left" variant="body1">
                    {hardware.batteryQuantity} - {hardware.battery.name}{hardware.batteryQuantity === 1 ? '' : "'s"}
                  </StyledTypography>
                  <StyledTypography strike>
                    ${Math.round(hardware.batteryCost).toLocaleString()}
                  </StyledTypography>
                </TextFieldsWrapper>
                <StyledSubtitle>
                  Including Installation
                </StyledSubtitle>
                <br />
                <TextFieldsWrapper>
                  <StyledTypography header>
                    With Incentives
                  </StyledTypography>
                  <StyledTypography header>
                    ${Math.round(hardware.batteryCost - (hardware.batteryCost * .3)).toLocaleString()}
                  </StyledTypography>
                </TextFieldsWrapper>
                <StyledButton variant="contained" color="secondary" onClick={handleRemoveBattery}>
                  Remove Battery
                </StyledButton>
              </>
            )}
          </CustomAccordion>
          <CustomAccordion
            headerTitle="Other Services"
            title={hardware.serviceCost !== 0  ? hardware.services.name : "Would you like to add an EV Charger?"}
            amount={hardware.serviceCost !== 0  ? "$" + hardware.serviceCost.toLocaleString() : ""}
            open={hardware.solutions.includes(3)}
          >
            <HardwareDetails
              title={solarData.services.title}
              options={solarData.services.options}
              selectedOption={hardware.services}
              setSelectedOption={(option) => updateHardware('services', option)}
            />
            <TotalHeader>Pricing</TotalHeader>
            <TextFieldsWrapper>
              <StyledTypography align="left" variant="body1">
                {hardware.services.name}
              </StyledTypography>
              <StyledTypography align="right" variant="body1">
                ${hardware.services.price}
              </StyledTypography>
            </TextFieldsWrapper>
            {hardware.serviceQuantity === 0 &&
              <StyledButton variant="contained" color="primary" onClick={handleAddCharger}>
                Add EV Charger
              </StyledButton>
            }
            {hardware.serviceQuantity !== 0 && (
              <>
            <SliderContainerServices>
              <SliderHeader align="left" variant="body1">
                Number of EV Chargers
              </SliderHeader>
              <CustomSlider
                value={hardware.serviceQuantity}
                onChange={handleSliderChangeServices}
                aria-label="EV Chargers"
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={1}
                marks={serviceMarks}
                min={1}
                max={3}
              />
            </SliderContainerServices>
                <TotalHeader>Services Total</TotalHeader>
                <TextFieldsWrapper>
                  <StyledTypography align="left" variant="body1">
                    {hardware.serviceQuantity} - {hardware.services.name}{hardware.serviceQuantity === 1 ? '' : "'s"}
                  </StyledTypography>
                  {hardware.systemSize > 0 ?
                    <StyledTypography align="right" variant="body1" strike>
                      ${hardware.serviceCost}
                    </StyledTypography>
                  :
                    <StyledTypography align="right" variant="body1">
                      ${hardware.serviceCost}
                    </StyledTypography>
                }
                </TextFieldsWrapper>
                <StyledSubtitle align="left">
                  Including Installation
                </StyledSubtitle>
                {hardware.systemSize !== 0 &&
                  <>
                    <br />
                    <TextFieldsWrapper>
                      <StyledTypography header>
                        With Incentives
                      </StyledTypography>
                      <StyledTypography header>
                        ${hardware.serviceCost - (hardware.serviceCost*.3)}
                      </StyledTypography>
                    </TextFieldsWrapper>
                  </>
                }
                <StyledButton variant="contained" color="secondary" onClick={handleRemoveCharger}>
                  Remove Charger
                </StyledButton>
              </>
            )}
          </CustomAccordion>
        </CustomContainer>
        {hardware.systemSize > 0 ? (
          <>
            <QuoteHeaderCustom>System Estimate</QuoteHeaderCustom>
            <TopContainerWrapper>
              <TopContainer>
                <Column>
                  <Value>{(hardware.systemSize/1000).toFixed(2)} kW</Value>
                  <Title>System Size</Title>
                </Column>
                <Column>
                  <Value>{Math.round(hardware.offset)} %</Value>
                  <Title>Energy Offset</Title>
                </Column>
                <Column>
                  <Value>${Math.round(hardware.totalAfterTaxCredit).toLocaleString()}</Value>
                  <Title>Total w/ Incentives</Title>
                </Column>
              </TopContainer>
            </TopContainerWrapper>
            <FinanceFieldsWrapper>
              <TextFieldsWrapper>
                <StyledTypography>
                  {hardware.systemSize / 1000} kW {hardware.module.name} | {hardware.inverter.name}
                </StyledTypography>
                <StyledTypography>
                  ${Math.round(hardware.solarCost).toLocaleString()}
                </StyledTypography>
              </TextFieldsWrapper>
              {hardware.batteryQuantity !== 0 && (
                <>
                  <TextFieldsWrapper>
                    <StyledTypography>
                      {hardware.batteryQuantity} - {hardware.battery.name}{hardware.batteryQuantity === 1 ? '' : "'s"}
                    </StyledTypography>
                    <StyledTypography>
                      ${Math.round(hardware.batteryCost).toLocaleString()}
                    </StyledTypography>
                  </TextFieldsWrapper>
                </>
              )}
              {hardware.serviceCost !== 0 && (
                <>
                  <TextFieldsWrapper>
                    <StyledTypography>
                    {hardware.serviceQuantity} - {hardware.services.name}{hardware.serviceQuantity === 1 ? '' : "'s"}
                    </StyledTypography>
                    <StyledTypography>
                      ${Math.round(hardware.serviceCost).toLocaleString()}
                    </StyledTypography>
                  </TextFieldsWrapper>
                </>
              )}
              <TextFieldsWrapper>
                <StyledTypography>
                  Total Price Before Incentives
                </StyledTypography>
                <StyledTypography>
                  ${Math.round(hardware.totalCost).toLocaleString()}
                </StyledTypography>
              </TextFieldsWrapper>
              <TextFieldsWrapper>
                <StyledTypography>
                  Federal Tax Credit (30%)
                </StyledTypography>
                <StyledTypography>
                  -${Math.round(hardware.totalCost * 0.3).toLocaleString()}
                </StyledTypography>
              </TextFieldsWrapper>
              <TextFieldsWrapper>
                <StyledTypography header blue>
                  Price After Incentives
                </StyledTypography>
                <StyledTypography header blue>
                  ${Math.round(hardware.totalCost - hardware.totalCost * 0.3).toLocaleString()}
                </StyledTypography>
              </TextFieldsWrapper>
            </FinanceFieldsWrapper>
            <TextWrapper>
              <StyledText>
              The values shown are preliminary estimates based on your address and utility rates, and do not account for factors such as your home's irradiance levels, roof orientation, or shading. For precise results, please click "Start Generating Design" This will allow us to analyze your property's unique conditions and provide an accurate system size, placement, and performance tailored to your location.              </StyledText>
            </TextWrapper>
          </>
        ) : (
          <>
            {(hardware.serviceQuantity > 0 || hardware.batteryQuantity > 0) &&
              <>
                <QuoteHeaderCustom>Pricing</QuoteHeaderCustom>
                {hardware.serviceCost !== 0 && (
                  <>
                    <TextFieldsWrapper>
                      <StyledTypography>
                        {hardware.serviceQuantity} - {hardware.services.name}{hardware.serviceQuantity === 1 ? '' : "'s"}
                      </StyledTypography>
                      <StyledTypography>
                        ${Math.round(hardware.serviceCost).toLocaleString()}
                      </StyledTypography>
                    </TextFieldsWrapper>
                  </>
                )}
                {hardware.batteryQuantity > 0 && (
                  <>
                    <TextFieldsWrapper>
                      <StyledTypography>
                        {hardware.batteryQuantity} - {hardware.battery.name}{hardware.batteryQuantity === 1 ? '' : "'s"}
                      </StyledTypography>
                      <StyledTypography>
                        ${Math.round(hardware.batteryCost).toLocaleString()}
                      </StyledTypography>
                    </TextFieldsWrapper>
                    <TextFieldsWrapper>
                      <StyledTypography>
                        Total Price Before Incentives
                      </StyledTypography>
                      <StyledTypography>
                        ${Math.round(hardware.totalCost).toLocaleString()}
                      </StyledTypography>
                    </TextFieldsWrapper>
                    <TextFieldsWrapper>
                      <StyledTypography>
                        Federal Tax Credit (30%)
                      </StyledTypography>
                      <StyledTypography>
                        -${Math.round(hardware.batteryCost * 0.3).toLocaleString()}
                      </StyledTypography>
                    </TextFieldsWrapper>
                  </>
                )}
                
                {(hardware.batteryQuantity !== 0 || hardware.serviceCost !== 0) &&
                  <>
                    <TextFieldsWrapper>
                      <StyledTypography header>
                        Total Cost
                      </StyledTypography>
                      <StyledTypography header>
                        ${Math.round((hardware.batteryCost - (hardware.batteryCost * 0.3)) + hardware.serviceCost).toLocaleString()}
                      </StyledTypography>
                    </TextFieldsWrapper>
                  </>
                }
                <TextWrapper>
                  <StyledText>
                  The pricing shown includes all necessary equipment and standard installation. However, please note that your home’s electrical setup and installation requirements may affect the final cost. Additionally, battery backup systems may qualify for a federal tax credit under the Investment Tax Credit (ITC), which allows you to claim a percentage of the system cost as a credit on your federal taxes. Be sure to consult with a tax professional to confirm eligibility and understand how this credit applies to your purchase. To proceed with the current pricing, click "Purchase Now."
                  </StyledText>
                </TextWrapper>
                {!success &&
                  <ButtonWrapper>
                    {submitting ? <CircularProgress size={24}/> : <StyledButton2 onClick={handleSignUp}>Purchase Now</StyledButton2>}
                  </ButtonWrapper>
                }
                {(success || error) &&
                  <SubmitMessageWrapper>
                    {success && <SubmitMessageText success>{success}</SubmitMessageText>}
                    {error && <SubmitMessageText>{error}</SubmitMessageText>}
                  </SubmitMessageWrapper>
                }
              </>
            }
          </>
        )}
      </QuoteInnerWrapper>
      }
    </QuoteGrid>
  );
};

export default Design;

import axios from 'axios';
import styled from 'styled-components';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IconContext } from 'react-icons'
import lunex from '../../images/LunexPower.webp';
// import logo from '../../images/lunexdirectlogo.jpg';
import TagManager from "react-gtm-module";

import { 
    ButtonContainer, 
    ErrorText, 
    ImageBg, 
    NextButton, 
    ProductListContainer, 
    QuoteAddressWrapper, 
    QuoteAppendedText, 
    QuoteAutocomplete, 
    QuoteBg, 
    QuoteGrid, 
    QuoteHeader, 
    QuoteHeader2, 
    QuoteHeader3, 
    QuoteInnerWrapper, 
    QuoteInput, 
    QuoteInputDeleteIcon, 
    QuoteInputLocateIcon, 
    QuoteInputTitle, 
    QuoteInputWrapper, 
    RegistrationErrorWrapper,
    ScrollAnimationWrapper
} from './QuoteElements'
import { Oval } from 'react-loading-icons';
import products from './ProductData';
import Product from './Product';
import ScrollAnimation from './ScrollAnimation';

const Locate = ({
    formState,
    setFormState,
    initialFormState,
    section,
    setSection,
    nextButton,
    updateFormControl,
    submitting,
    customerErrMesg,
    setCustomerErrMesg,
    loadingTexts,
    loadingTextIndex,
    hardware,
    updateHardware,
    updateSolutions
}) => {

  // const [showLocate, setShowLocate] = useState(false);

  const onPlaceSelected = useRef(() => undefined);

  const validStates = [
    'FL',
    'MA',
    'RI',
    'CT'
  ]
  
  const updateFormControlAddy = useCallback((event) => {
    setCustomerErrMesg('');
    if (!event || !event.address_components) return;

    let zip = '';
    let state = '';

    event.address_components.forEach(component => {
      if (component.types.includes('postal_code')) {
        zip = component.short_name;
      }
      if (component.types.includes('administrative_area_level_1')) {
        state = component.short_name;
      }
    });

    const updatedFormState = { ...formState, zip, address: event.formatted_address, state };
    setFormState(updatedFormState);
  }, [formState, setFormState, setCustomerErrMesg]);

  const handleLocate = async () => {
    // setShowLocate(true);
    try{
      navigator.geolocation.getCurrentPosition(async function(position) {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&key=' + process.env.REACT_APP_GOOGLE_MAPS_API

        const result = await axios.get(url);

        // console.log("google geocode resp =", result);

        updateFormControlAddy(result.data.results[0]); 

      });
    }
    catch(error){
      // console.log("Error encounterd while getting location ", error);
    }
  }

  const handleDelete = () => {
    setCustomerErrMesg('');
    // setShowLocate(false);
    const updatedFormState = { ...formState };
    updatedFormState['address'] = '';
    setFormState(updatedFormState);
  }
  useEffect(() => {
    onPlaceSelected.current = (place) => {
      updateFormControlAddy(place[0]);
    };
  }, [updateFormControlAddy]);

  const nextButtonLocate = () =>  {
    TagManager.dataLayer({
      gtmId: "GTM-MD88S8R",
      dataLayer: {
        event: 'locate_next_clicked'
      }
    });
    if(!validStates.includes(formState.state))
    {
      setCustomerErrMesg("Sorry, we are not available in your state yet");
      TagManager.dataLayer({
        gtmId: "GTM-MD88S8R",
        dataLayer: {
          event: 'locate_next_clicked_invalid_state'
        }
      });
    }
    else{
      // setShowLocate(false);
      nextButton();
      setCustomerErrMesg("");
    }
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter' || e.which === 13){
      nextButtonLocate();
      // call your method here
     }
  }

  const handleInputChange = (e) => {
    const updatedFormState = { ...formState, address: e.target.value };
    setFormState(updatedFormState);
  };

  const toggleSelect = (id) => {
    console.log("id", id);
    updateSolutions(id);
    // updateHardware('solutions', (prevSolutions) => {
    //   if (prevSolutions.includes(id)) {
    //     return prevSolutions.filter(serviceId => serviceId !== id);
    //   } else {
    //     return [...prevSolutions, id];
    //   }
    // });
  };

  const design = false;

  return (
    <QuoteGrid>
      <ScrollAnimationWrapper>
      {/* <QuoteBg> */}
        <ScrollAnimation />
      </ScrollAnimationWrapper>
        {/* <ImageBg src={lunex} alt={'Lunex Direct'}/> */}
      {/* </QuoteBg> */}
      <QuoteInnerWrapper>
          
          <QuoteHeader>Reimagine Your Power</QuoteHeader>
          <QuoteHeader3>Enter Home Details</QuoteHeader3>
          {/* <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'}/> */}
          <QuoteInputWrapper>
          <QuoteInputTitle htmlFor='address'>Home Address</QuoteInputTitle>
          <QuoteAddressWrapper>
            <IconContext.Provider
                value={{ size: '35px' }}
            >
            {formState.address === '' ? (
                <>
                  <QuoteAutocomplete 
                    apiKey="AIzaSyAOAiAN5-83Fjxb45YLTjxGtBkAVaY1_cI"
                    onPlaceSelected={(...args) => onPlaceSelected.current(args)}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "us" },
                    }}
                    id='address'
                    defaultValue={formState.address} 
                  />
                  <QuoteInputLocateIcon onClick={handleLocate} />
                </>
              ) : (
                <>
                  <QuoteInput 
                    type='text' 
                    id='address' 
                    value={formState.address} 
                    onChange={handleInputChange} 
                  />
                  <QuoteInputDeleteIcon onClick={handleDelete} />
                </>
              )}
            
            </IconContext.Provider>
          </QuoteAddressWrapper>
          </QuoteInputWrapper>
          <br />
          <QuoteInputWrapper>
              <QuoteInputTitle htmlFor='electricBill'>Average Electric Bill</QuoteInputTitle>
              <QuoteAddressWrapper>
                <QuoteInput type='text' id='electricBill' value={formState.electricBill} onChange={(updateFormControl)} onKeyPress={handleKeyPress}/>
                <QuoteAppendedText>/ mo</QuoteAppendedText>
              </QuoteAddressWrapper>
          </QuoteInputWrapper>
          <br />
          <QuoteHeader2>Select Your Energy Solutions</QuoteHeader2>
          <QuoteHeader3>Customize your energy setup by choosing from our range of solar panels, battery backups, and EV chargers.</QuoteHeader3>
          <ProductListContainer>
            {products.map((product) => (
              <Product 
                key={product.id} 
                product={product}
                solution={hardware.solutions.includes(product.id)}
                toggleSelect={toggleSelect}
               />
            ))}
          </ProductListContainer>
          {!submitting&&
            <ButtonContainer>
              <NextButton onClick={nextButtonLocate} disabled={!formState.address || !formState.electricBill} >Next</NextButton>
            </ButtonContainer>
          }
          {submitting &&
            <>
              <ButtonContainer>
                <NextButton>
                  <Oval height='1.5rem'/>
                </NextButton>
              </ButtonContainer>
              <RegistrationErrorWrapper>
                {loadingTexts[loadingTextIndex]}
              </RegistrationErrorWrapper>

            </>
          }
          {customerErrMesg &&
            <RegistrationErrorWrapper>
                <ErrorText>{customerErrMesg}</ErrorText>
            </RegistrationErrorWrapper>
          }
          
      </QuoteInnerWrapper>
    </QuoteGrid>
    )
}

export default Locate
import qcell from '../../images/qcell.webp';
import rec from '../../images/REC_Alpha.webp';
export const ModuleData = [
    {
        brand: 'QCELL 410',
        module: 'qcell',
        image: qcell,
        imageAlt: 'QCell 410',
        name: 'Q.PEAK DUO BLK ML-G10+',
        kw: 410,
        warranty: '25',
        datasheet:'/files/qcell_400.pdf',
        rateFL: 2450,
        rateNE: 3200,
    },
    {
        brand: 'REC 405',
        module: 'rec',
        image: rec,
        imageAlt: 'REC 405',
        name: 'REC405AA Pure Black',
        kw: 405,
        warranty: '25',
        datasheet:'/files/rec_405.pdf',
        rateFL: 2500,
        rateNE: 3300,
    }
]
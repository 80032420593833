import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import animationData from '../../images/lunex_model/fullmotion.json';

const AnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
`;

const LottieStyled = styled(Lottie)`
  flex-shrink: 0;
  width: auto;
  height: 100%;
`;

const ScrollAnimation = ({ scrollContainerRef, useScroll = false }) => {
  const lottieRef = useRef();

  useEffect(() => {
    if (!useScroll || !scrollContainerRef?.current || !lottieRef.current) return;

    // const handleScroll = throttle(() => {

    //     if (!lottieRef.current) {
    //         console.warn('lottieRef.current is null');
    //         return;
    //       }
    //     console.log('Scroll event detected');
    //   const container = scrollContainerRef.current;
    //   const scrollTop = container.scrollTop;
    //   const maxScrollTop = container.scrollHeight - container.clientHeight;
    //   const scrollPercent = scrollTop / maxScrollTop;

    //   const instance = lottieRef.current;
    //   const totalFrames = instance.totalFrames;
    //   const frame = totalFrames * scrollPercent;
    //   instance.goToAndStop(frame, true);
    // }, 50);

    const handleScroll = throttle(() => {
        const container = scrollContainerRef.current;
        const scrollTop = container.scrollTop;
        const maxScrollTop = container.scrollHeight - container.clientHeight;
        const scrollPercent = scrollTop / maxScrollTop;
  
        const instance = lottieRef.current;
  
        // Option A: Using getDuration(true)
        const totalFrames = instance.getDuration(true);
  
        // Option B: Using animationData.op
        // const totalFrames = instance.animationData.op;
  
        const frame = totalFrames * scrollPercent;
  
        // console.log('scrollTop:', scrollTop);
        // console.log('maxScrollTop:', maxScrollTop);
        // console.log('scrollPercent:', scrollPercent);
        // console.log('totalFrames:', totalFrames);
        // console.log('frame:', frame);
  
        instance.goToAndStop(frame, true);
      }, 50);

    // const handleScroll = () => {
    //     const container = scrollContainerRef.current;
    //     const scrollTop = container.scrollTop;
    //     const maxScrollTop = container.scrollHeight - container.clientHeight;
    //     const scrollPercent = scrollTop / maxScrollTop;
      
    //     console.log('scrollTop:', scrollTop);
    //     console.log('maxScrollTop:', maxScrollTop);
    //     console.log('scrollPercent:', scrollPercent);
      
    //     const instance = lottieRef.current;
    //     const totalFrames = instance.totalFrames;
    //     const frame = totalFrames * scrollPercent;
    //     console.log('frame:', frame);
      
    //     instance.goToAndStop(frame, true);
    //   };
      

    const container = scrollContainerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    //   handleScroll.cancel();
    };
  }, [scrollContainerRef, useScroll, lottieRef.current]);

  const testAnimation = () => {
    if (lottieRef.current) {
      lottieRef.current.goToAndStop(50, true); // Go to frame 50
    }
  };

  return (
    <AnimationWrapper>
      <LottieStyled
        lottieRef={lottieRef}
        animationData={animationData}
        loop={!useScroll}
        autoplay={!useScroll}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
      />
    </AnimationWrapper>
  );
};

export default ScrollAnimation;

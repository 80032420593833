import React, {useEffect} from 'react'
import { 
    AddressUrl, 
    Arrow, 
    BackButton, 
    ButtonContainer, 
    QuoteInnerWrapper, 
    ErrorText, 
    ImageBg, 
    LunexHeaderLogo, 
    NextButton, 
    QuoteBg, 
    QuoteGrid, 
    QuoteHeader, 
    QuoteInput, 
    QuoteInputTitle, 
    QuoteInputWrapper, 
    QuoteTextSubtitle, 
    QuoteTextWrapper, 
    RegistrationErrorWrapper,
    ScrollAnimationWrapper
} from './QuoteElements'
import { Oval } from 'react-loading-icons'
import lunex from '../../images/LunexPower.webp';
import logo from '../../images/lunexdirectlogo.jpg';
import ScrollAnimation from './ScrollAnimation';

const Customer = ({
    addressClick,
    formState,
    updateFormControl,
    errorFormState,
    nextButton,
    nextClicked,
    dataLoaded,
    submitting,
    customerErrMesg,
    batteryOnly,
    setBatteryOnly,
    backButton,
    showLoadingIcon
}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

  return (
    <QuoteGrid>
        <ScrollAnimationWrapper>
            <ScrollAnimation />
        </ScrollAnimationWrapper>
        <QuoteInnerWrapper>
            <BackButton onClick={backButton}>
                <Arrow>&lt;</Arrow>
                Back
            </BackButton>
            <QuoteHeader>See your Solar Difference with</QuoteHeader>
            <QuoteHeader>
                <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'} customer={true}/>
            </QuoteHeader>
            <QuoteInputWrapper>
                <AddressUrl onClick={addressClick} >{formState.address}</AddressUrl>
            </QuoteInputWrapper>
            <br />
            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='name'>Name</QuoteInputTitle>
                <QuoteInput type='text' id='name' value={formState.name} onChange={updateFormControl} required/>
            </QuoteInputWrapper>
            <br />
            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='email'>Email</QuoteInputTitle>
                <QuoteInput type='text' id='email' value={formState.email} onChange={updateFormControl} required/>
            </QuoteInputWrapper>
            <br />
            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='phone'>Phone</QuoteInputTitle>
                <QuoteInput type='text' id='phone' value={formState.phone} onChange={updateFormControl}/>
            </QuoteInputWrapper>
            {/* <br />
            <QuoteInputWrapper>
                <QuoteInputTitleCheckbox>
                    Only need battery storage?
                    <QuoteInputCheckbox type="checkbox" checked={batteryOnly} onChange={handleCheckbox} />       
                </QuoteInputTitleCheckbox>

            </QuoteInputWrapper> */}
            
            <br />
            <QuoteTextWrapper>
                <QuoteTextSubtitle>We will not spam you or share your information with anyone</QuoteTextSubtitle>
            </QuoteTextWrapper>
            <br />
            <ButtonContainer>
                {!submitting &&
                    <NextButton onClick={nextButton} disabled={!formState.name || !errorFormState.hasEmailAndIsValid}>GET INSTANT PRICING</NextButton>
                }
                {submitting &&
                    <NextButton loading={showLoadingIcon} disabled={showLoadingIcon}>
                        <Oval height='1.5rem'/>
                    </NextButton>                      
                }
            </ButtonContainer>
            {customerErrMesg &&
                <RegistrationErrorWrapper>
                    <ErrorText>{customerErrMesg}</ErrorText>
                </RegistrationErrorWrapper>
            }
        </QuoteInnerWrapper>
    </QuoteGrid>
  )
}

export default Customer
import React from 'react';
import styled from 'styled-components';

const ProductCard = styled.div`
  border: 5px solid ${props => props.selected ? '#1BA8F1' : '#e0e0e0'};
  border-radius: 10px;
  overflow: hidden;
  height: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 125px;
  overflow: hidden;
`;

const ProductInfo = styled.div`
  /* padding: 20px; */
  text-align: center;
  /* margin: 20px; */
  /* margin-bottom: 30px; */
`;

const ProductTitle = styled.h3`
  /* font-size: 1.6rem; */
  /* margin-top: 20px; */
  /* margin-bottom: -10px; */
`;

const ProductSubtitle = styled.p`
  color: #555;
`;

const Product = ({ 
    product, 
    solution, 
    toggleSelect 
}) => {

  const handleClick = () => {
    toggleSelect(product.id);
  };
    return (
      <ProductCard selected={solution} onClick={handleClick}>
        <ProductImage src={product.image} alt={product.alt} />
        <ProductInfo>
          <ProductTitle>{product.title}</ProductTitle>
          <ProductSubtitle>{product.subtitle}</ProductSubtitle>
        </ProductInfo>
      </ProductCard>
    );
  };

export default Product;

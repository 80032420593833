import { useState } from 'react';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import { ModuleData } from '../components/Quote/ModuleData';

const useEstimate = (formState, responseState, setResponseState, setDataLoaded, updateHardware) => {
  const [submitting, setSubmitting] = useState(false);
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);
  const [satelliteImageUrl, setSatelliteImageUrl] = useState('');
  const [customerErrMesg, setCustomerErrMesg] = useState('');

  const loadingTexts = [
    "Retrieving address information...",
    "Acquiring utility details...",
    "Searching for tariffs...",
    "Measuring solar insolation rates...",
    "Assessing system size..."
  ];

  const getEstimate = async () => {
    setSubmitting(true);
    try {
      // Display loading text
      const intervalId = setInterval(() => {
        setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
      }, 1500);

      // API call to get estimate data
      const url = `${process.env.REACT_APP_API_URL}/estimate?zip=${formState.zip}&aveBill=${formState.electricBill}&address=${formState.address}`;
      const result = await axios.get(url);

      // Update response state with the result
      const updatedResponseState = { ...responseState, 
        estimateSize: (result.data.estimate_size * 1000),
        solarProductionMonthly: result.data.solarProductionMonthly,
        projectId: result.data.projectId,
        designId: result.data.designId,
      };

      setResponseState(updatedResponseState);

      // Calculate module details
      // const module_amount = Math.ceil((result.data.estimate_size * 1000) / ModuleData[0].kw);
      // const module_size = (module_amount * ModuleData[0].kw) / 1000;
      // const module_offset = ((module_size / result.data.estimate_size) * 100).toFixed(0);
      // const module_subtotal = formState.state === 'FL'
      //   ? module_size * ModuleData[0].rateFL
      //   : module_size * ModuleData[0].rateNE;

      // Fetch satellite image
      const image_url = `${process.env.REACT_APP_API_URL}/service/getHomeSatelliteImage?address=${formState.address}`;
      const imageResponse = await axios.get(image_url, { responseType: 'blob' });
      const imageBlob = imageResponse.data;
      const imageUrl = URL.createObjectURL(imageBlob);
      setSatelliteImageUrl(imageUrl);
      updateHardware('designImage', imageUrl);

      // Push data to Tag Manager
      TagManager.dataLayer({
        gtmId: "GTM-MD88S8R",
        dataLayer: {
          event: 'locate_next_clicked_success'
        }
      });

      setDataLoaded(true);
    } catch (error) {
      console.error("Error encountered", error.message);
      setCustomerErrMesg(error.message);
      TagManager.dataLayer({
        gtmId: "GTM-MD88S8R",
        dataLayer: {
          event: 'locate_next_clicked_estimate_error'
        }
      });
    } finally {
      setSubmitting(false);
    }
  };

  return { getEstimate, submitting, setSubmitting, satelliteImageUrl, loadingTextIndex, loadingTexts, customerErrMesg, setCustomerErrMesg };
};

export default useEstimate;

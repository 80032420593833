import tesla from '../../images/Powerwall.jpg';
import enphase from '../../images/Enphase.webp';
import franklin from '../../images/franklin.webp';
export const StorageData = [
    {
        batteryType: 'tesla',
        brand: 'Tesla',
        image: tesla,
        imageAlt: 'Tesla Powerwall 3',
        name: 'Powerwall 3 AC',
        kw: 13.5,
        warranty: '10',
        datasheet:'/files/Powerwall_2.pdf',
        firstUnit: '15,000',
        additionalUnits: '10k'
    },
    {
        batteryType: 'encharge',
        brand: 'Enphase',
        image: enphase,
        imageAlt: 'Encharge Enphase 5P',
        name: 'Encharge 10 AC',
        kw: 10,
        warranty: '10',
        datasheet:'/files/Encharge_10.pdf',
        firstUnit: '15,000',
        additionalUnits: '10k'
    },
    // {
    //     batteryType: 'franklin',
    //     brand: 'FranklinWH',
    //     image: franklin,
    //     imageAlt: 'Franklin aGate',
    //     name: 'Franklin Home Power',
    //     kw: 13.6,
    //     warranty: '12',
    //     datasheet:'/files/Franklin.pdf',
    //     firstUnit: '15,000',
    //     additionalUnits: '10k'
    // }
]

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IconButton, CircularProgress, ToggleButtonGroup, ToggleButton, Select, MenuItem, TextField, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FinanceData } from './FinanceData';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import ScrollAnimation from './ScrollAnimation';
import Carousel from './Carousel';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Lottie from 'lottie-react';
import gen1 from '../../images/lottie/gen1.json';
import gen2 from '../../images/lottie/gen2.json';
import gen3 from '../../images/lottie/gen3.json';
import gen4 from '../../images/lottie/gen4.json';

const PopupContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* margin-top: 10px; */
  height: calc(100% - 190px);
  width: auto;
  margin-left: 20px;
  margin-top: 72px;
  /* padding-top: 20px; */
  /* background-color: transparent; */
  background-color: #f8f4f4;

  border-radius: 20px;
  z-index: 10;
  overflow-y: auto;
  max-width: 100%;

  @media screen and (max-width: 1550px) {
    overflow-y: scroll;
  }

  @media screen and (max-width: 900px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 132px);
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 80px;
    /* padding: 0; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    overflow-y: scroll;
  }
`;

const CloseWrapper = styled.div`
  width: 99%;
  display: flex;
  justify-content: end;  
  margin-top: 10px;
`



const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  max-width: 100%;
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 150px;
`

const FinanceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    margin: 0 auto;
    gap: 5px;
    width: 98%;
  } 
`;

const StyledSelect = styled(Select)`
  /* height: 40px; */
`;

const DownPaymentField = styled(TextField)`
  width: 150px !important;

  @media screen and (max-width: 900px) {
    width: 100% !important;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;


const TopContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: ${({fin}) => (fin ? '100%' : '85%')};

  @media screen and (max-width: 900px) {
    width: 100%;
  }

`

const TopContainerWrapper2 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const TopContainer = styled.div`
  width: 100%;
  max-width: 500px;

  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: ${({bottombar}) => (bottombar ? '0' : '20px')};
  margin: 10px;
  text-align: center;

  @media screen and (max-width: 900px) {
    gap: 5px;
    margin: 5px;
    }
`;

const Value = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;

  @media screen and (max-width: 900px) {
    font-size: 1.1rem;
    }
`;

const Title = styled.div`
  font-size: 0.8rem;
  font-weight: normal;
  color: #555;

  @media screen and (max-width: 900px) {
    font-size: 12px;
    }
`;

const TextFieldsWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 20px;
  margin: 10px;
`;

const StyledTypography = styled(Typography)`
  font-size: ${({header}) => (header ? '1.2rem !important' : '0.85rem !important')};
  color: ${({blue}) => (blue ? '#1976d2' : 'black')};
  font-weight: ${({header}) => (header ? 'bold !important' : 'normal')};
  margin: 5px 0;
  text-align: ${({design}) => (design ? 'center' : 'left')};
  text-decoration: ${({strike}) => (strike ? 'line-through' : 'none')};
`;

const StyledHeader = styled(Typography)`
  font-size: 1.5rem !important;
  color: #000;
  font-weight: bold !important;
  /* margin: 10px 0 !important; */
  /* padding-top: 10px; */
  text-align: center;
`;

const StyledTypography2 = styled(Typography)`
  font-size: 0.7rem !important;
  color: grey !important;
  margin: 10px 0;
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  justify-content: center;
  max-width: 100%;
  /* align-items: center; */
  /* max-width: 1000px; */

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const FinanceFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;

  @media screen and (max-width: 900px) {
    /* padding: 5px; */
    /* margin: 15px; */
    flex-direction: column;
    /* align-items: center; */
  }
`;

const Column = styled.div`
  flex: 1;
  width: 100%;
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  margin: 5px;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* margin-bottom: 20px;
  margin-top: 20px; */
  margin: 20px;
`;

const DesignWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  /* margin: 50px; */

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`

const DesignContainer = styled.div`

`

const DesignImage = styled.img`
  height: 100%;
  width: auto;
  display: flex;
  max-width: 350px;
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 20px;

  @media screen and (max-width: 900px) {
    width: 90%;
    height: auto;
    max-height: 300px;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  min-width: 200px;
  margin: 20px;
  display: flex;
  justify-content: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  /* display: flex;
  align-items: center; */
  margin-top: 20px;
  background-color: #1976d2 !important;
  color: white !important;

  &:hover {
    background-color: #1565c0 !important;
  }
`;

const SubmitMessageWrapper = styled.div`
    width: 100%;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media screen and (max-width: 480px) {
      width: 80%;
      margin: 0 auto;
    }

`

const SubmitMessageText = styled.div`
  text-align: center;
  color: ${({success}) => (success ? '#388e3c' : '#d32f2f')};
  font-size: 1.5rem;
  margin-top: 0.25rem;
`


const LottieContainer = styled.div`
  height: 350px;
  width: auto;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  min-width: 500px;
  
  @media screen and (max-width: 900px) {
    padding-top: 20px;
    width: 80%;
    margin: 0 auto;
    height: auto;
    max-height: 200px;
    min-width: 0;
  }

`

const animations = [gen1, gen2, gen3, gen4];

const PricingPopup = ({ 
  hardware, 
  onClick, 
  updateHardware, 
  updateLender, 
  updateProduct, 
  responseState, 
  formState,
  satelliteImageUrl,
  setDesignLocked,
  designStatus
}) => {
  const [toggleView, setToggleView] = useState('Cash');
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleToggleChange = (event, newView) => {
    if (newView !== null) {
      setToggleView(newView);
    }
  };

  async function handleSignUpButton() {
    updateHardware('paymentType', toggleView);

    await handleSignUp();
  }

  async function handleSignUp() {
    setSubmitting(true);
    setError(false);

    const data = JSON.stringify({
      "projectId": responseState.projectId,
      "hardware":hardware,
    });
    
    const config = {
      method: 'put',
      headers: { 
        'Content-Type': 'application/json', 
      },
      body: data
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate`, config);
  
    if (response.ok) {
      setSuccess('Thank you! You will receive an email with your proposal shortly!');
    } else {
      setError('Sign Up failed');
    }
    
    setSubmitting(false);
  }

  const [currentAnimation, setCurrentAnimation] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAnimation((prev) => (prev + 1) % animations.length);
    }, 5000); // Change animation every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);



  return (
    <>
      <PopupContainer>
        {/* <CloseWrapper>
          <IconButton onClick={onClick}>
            <CloseIcon />
          </IconButton>
        </CloseWrapper> */}
        <HeaderWrapper>
          <StyledHeader>
            Your System
          </StyledHeader>
        </HeaderWrapper>
        
        <ContentWrapper>
          
          {designStatus !== 'completed' ? (
            <DesignWrapper>
              <Column>
              <DesignImage src={hardware.designImage} alt="Design Preview" />
              <StyledTypography header design>{hardware.designText}</StyledTypography>
              </Column>
              <Column>
                <LottieContainer>
                  <Lottie animationData={animations[currentAnimation]} style={{ height: '100%', width: 'auto'  }} />
                </LottieContainer>
              </Column>
            </DesignWrapper>
          ) : (
            <>
              <Column>
                <CarouselWrapper>
                  <Carousel 
                    hardware={hardware}
                    designStatus={designStatus}
                  />
                  <TopContainerWrapper>
                    <TopContainer>
                      <Column>
                        <Value>{(hardware.systemSize/1000).toFixed(2)} kW</Value>
                        <Title>System Size</Title>
                      </Column>
                      <Column>
                        <Value>{Math.round(hardware.annualProduction).toLocaleString()} kWh</Value>
                        <Title>Annual Production</Title>
                      </Column>
                      <Column>
                        <Value>{Math.round(hardware.offset)} %</Value>
                        <Title>Energy Offset</Title>
                      </Column>
                    </TopContainer>
                  </TopContainerWrapper>
                </CarouselWrapper>            
              </Column>
              <Column>
                {!success && 
                  <ToggleWrapper>
                    <StyledToggleButtonGroup
                      value={toggleView}
                      exclusive
                      onChange={handleToggleChange}
                      aria-label="Toggle Pricing View"
                    >
                      <StyledToggleButton value="Cash" aria-label="Cash">
                        Cash
                      </StyledToggleButton>
                      <StyledToggleButton value="Finance" aria-label="Finance">
                        Finance
                      </StyledToggleButton>
                    </StyledToggleButtonGroup>
                  </ToggleWrapper>
                }
                {toggleView === 'Finance' && (
                <>
                    {/* Finance View Content */}
                    <br />
                    {/* <StyledTypography header>Choose Your Finance Solution</StyledTypography> */}
                    <FinanceWrapper>
                      <TextField
                        select
                        label="Finance Type"
                        value={hardware.selectedProduct.product || ''}
                        onChange={(e) => updateProduct(e.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        disabled={hardware.paymentType !== ''}
                      >
                        {FinanceData.Lenders.map((lenderObj) => {
                          const lenderName = Object.keys(lenderObj)[0];
                          return lenderObj[lenderName].map((productData) => (
                            <MenuItem key={`${lenderName}-${productData.product}`} value={productData.product}>
                              {`${productData.product}`}
                            </MenuItem>
                          ));
                        })}
                      </TextField>
                      <DownPaymentField
                        label="Down Payment"
                        type="number"
                        value={hardware.downPayment === 0 ? '' : hardware.downPayment}
                        disabled={hardware.paymentType !== ''}
                        onChange={(e) => {
                          let value = e.target.value;
                      
                          if (value === "") {
                            updateHardware("downPayment", "");
                          } else {
                            value = parseInt(value, 10);
                      
                            // If the parsed value is greater than totalCost, set it to totalCost
                            if (value > (hardware.totalCost - hardware.totalCost * 0.3)) {
                              updateHardware("downPayment", Math.round(hardware.totalCost - hardware.totalCost * 0.3));
                            } else if (value < 0 || isNaN(value)) {
                              // If the value is negative or NaN, set it to 0
                              updateHardware("downPayment", 0);
                            } else {
                              // Otherwise, set the value entered by the user
                              updateHardware("downPayment", value);
                            }
                          }
                        }}
                        onBlur={(e) => {
                          let value = e.target.value;
                          if (isNaN(value) || value === "") {
                            updateHardware("downPayment", 0);  // Set to 0 when blurred with empty or NaN value
                          }
                        }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    </FinanceWrapper>
                    <TopContainerWrapper fin>
                      <TopContainer>
                        <Column>
                          <Value>30 Years</Value>
                          <Title>Finance Term</Title>
                        </Column>
                        <Column>
                          <Value>{hardware.selectedProduct.apr} %</Value>
                          <Title>APR</Title>
                        </Column>
                        {hardware.downPayment > 0 &&
                          <Column>
                            <Value>${hardware.downPayment.toLocaleString()}</Value>
                            <Title>Down Payment</Title>
                          </Column>
                        }
                        
                        <Column>
                          <Value>${hardware.financedMonthlyPaymentAfterTaxCredit.toFixed(2)}*</Value>
                          <Title>Financed Payment /mo</Title>
                        </Column>
                      </TopContainer>
                    </TopContainerWrapper>
                    <TextFieldsWrapper>
                        <StyledTypography>
                          {hardware.systemSize / 1000} kW {hardware.module.name} | {hardware.inverter.name}
                        </StyledTypography>
                        <StyledTypography>
                          ${Math.round(hardware.solarCost).toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                      {hardware.batteryQuantity !== 0 && (
                        <>
                          <TextFieldsWrapper>
                            <StyledTypography>
                              {hardware.batteryQuantity} - {hardware.battery.name}{hardware.batteryQuantity === 1 ? '' : "'s"}
                            </StyledTypography>
                            <StyledTypography>
                              ${Math.round(hardware.batteryCost).toLocaleString()}
                            </StyledTypography>
                          </TextFieldsWrapper>
                        </>
                      )}
                      {hardware.serviceCost !== 0 && (
                        <>
                          <TextFieldsWrapper>
                            <StyledTypography>
                            {hardware.serviceQuantity} - {hardware.services.name}{hardware.serviceQuantity === 1 ? '' : "'s"}
                            </StyledTypography>
                            <StyledTypography>
                              ${Math.round(hardware.serviceCost).toLocaleString()}
                            </StyledTypography>
                          </TextFieldsWrapper>
                        </>
                      )}
                    <TextFieldsWrapper>
                      <StyledTypography>
                        Total Price Before Incentives
                      </StyledTypography>
                      <StyledTypography>
                        ${Math.round(hardware.totalCost).toLocaleString()}
                      </StyledTypography>
                    </TextFieldsWrapper>
                    <TextFieldsWrapper>
                      <StyledTypography>
                        Federal Tax Credit (30%)
                      </StyledTypography>
                      <StyledTypography>
                        -${Math.round(hardware.totalCost * 0.3).toLocaleString()}
                      </StyledTypography>
                    </TextFieldsWrapper>
                    {hardware.downPayment > 0 &&
                      <TextFieldsWrapper>
                        <StyledTypography>
                          Down Payment
                        </StyledTypography>
                        <StyledTypography>
                          -${hardware.downPayment.toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                    }
                    
                    <TextFieldsWrapper>
                      <StyledTypography>
                        Financed Amount
                      </StyledTypography>
                      <StyledTypography>
                        ${Math.round(hardware.totalCost- hardware.downPayment).toLocaleString()}
                      </StyledTypography>
                    </TextFieldsWrapper>
                    <TextFieldsWrapper>
                      <StyledTypography>
                        Financed Payment Pre-Incentive
                      </StyledTypography>
                      <StyledTypography>
                        ${hardware.financedMonthlyPaymentPreTaxCredit.toFixed(2)}
                      </StyledTypography>
                    </TextFieldsWrapper>
                    <TextFieldsWrapper>
                      <StyledTypography header blue>
                        Financed Payment After Incentive
                      </StyledTypography>
                      <StyledTypography header blue>
                        ${hardware.financedMonthlyPaymentAfterTaxCredit.toFixed(2)}
                      </StyledTypography>
                    </TextFieldsWrapper>

                    {/* <FinanceWrapper>
                      <Select
                        value={hardware.selectedLender}
                        onChange={(e) => updateLender(e.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                      >
                        {FinanceData.Lenders.map((lenderObj) => {
                          const lenderName = Object.keys(lenderObj)[0];
                          return (
                            <MenuItem key={lenderName} value={lenderName}>
                              {lenderName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Select
                        value={hardware.selectedProduct.product || ''}
                        onChange={(e) => updateProduct(e.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                      >
                        {FinanceData.Lenders.find(l => l[hardware.selectedLender])[hardware.selectedLender].map((productData) => (
                          <MenuItem key={productData.product} value={productData.product}>
                            {productData.product}
                          </MenuItem>
                        ))}
                      </Select>
                    </FinanceWrapper> */}
                    {/* <TopContainer bottombar={true}>
                    {hardware.selectedProduct.type === 'Loan' ?
                      <Column>
                        <Value>{hardware.selectedProduct.apr} %</Value>
                        <Title>APR</Title>
                      </Column>
                      :
                      <Column>
                        <Value>{hardware.selectedProduct.fee} %</Value>
                        <Title>Dealer Fee</Title>
                      </Column>
                    }
                      <Column>
                        <Value>${(hardware.totalCost + (hardware.totalCost*(hardware.selectedProduct.fee/100)) ).toFixed(2).toLocaleString()}</Value>
                        <Title>Financed Amount</Title>
                      </Column>
                      <Column>
                        <Value>${hardware.financedMonthlyPaymentPreTaxCredit.toFixed(2)}</Value>
                        <Title>Monthly Payment</Title>
                      </Column>
                    </TopContainer>
                    <FinanceWrapper>
                      
                    </FinanceWrapper>
                    <TextFieldsWrapper>
                      <StyledTypography>Financed Total: {hardware.financedTotal} / mo</StyledTypography>
                      <StyledTypography>Financed Monthly Payment: {(hardware.financedMonthlyPaymentAfterTaxCredit).toFixed(2)} / mo</StyledTypography>
                    </TextFieldsWrapper> */}
                    <TextWrapper>
                      <StyledTypography2>
                      The estimated payment is based on the current APR and may vary until your credit application is approved. Installation costs are included, though additional upgrades, such as electrical panel work or hidden conduit, may be required. While rebates and incentives may be available, eligibility is not guaranteed. A Federal Investment Tax Credit (ITC) may apply to solar systems, storage batteries, and EV chargers, provided your tax liability meets or exceeds the credit amount. Consult a tax advisor to confirm your eligibility for any rebates or tax credits.                  </StyledTypography2>
                    </TextWrapper>
                    <br />
                    <TextWrapper>
                      <StyledTypography2>
                      I authorize Lunex Direct to reach out to me regarding this inquiry, as well as to provide additional information about Lunex products and services using the contact details I've provided. I acknowledge that calls or texts may utilize automated or computer-assisted dialing systems, or pre-recorded messages. Standard message and data rates may apply.                
                      </StyledTypography2>
                    </TextWrapper>
                  </>
                )}
                {toggleView === 'Cash' &&
                  <>
                    <TopContainerWrapper fin>
                      <TopContainer>
                        <Column>
                          <Value>${Number(hardware.totalCost.toFixed(0)).toLocaleString()}</Value>
                          <Title>Total Cost</Title>
                        </Column>
                        <Column>
                          <Value>${Number(hardware.totalAfterTaxCredit.toFixed(0)).toLocaleString()}</Value>
                          <Title>Total After Tax Credit</Title>
                        </Column>
                      </TopContainer>
                    </TopContainerWrapper>
                    <FinanceFieldsWrapper>
                      <TextFieldsWrapper>
                        <StyledTypography>
                          {hardware.systemSize / 1000} kW {hardware.module.name} | {hardware.inverter.name}
                        </StyledTypography>
                        <StyledTypography>
                          ${Math.round(hardware.solarCost).toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                      {hardware.batteryQuantity !== 0 && (
                        <>
                          <TextFieldsWrapper>
                            <StyledTypography>
                              {hardware.batteryQuantity} - {hardware.battery.name}{hardware.batteryQuantity === 1 ? '' : "'s"}
                            </StyledTypography>
                            <StyledTypography>
                              ${Math.round(hardware.batteryCost).toLocaleString()}
                            </StyledTypography>
                          </TextFieldsWrapper>
                        </>
                      )}
                      {hardware.serviceCost !== 0 && (
                        <>
                          <TextFieldsWrapper>
                            <StyledTypography>
                              {hardware.serviceQuantity} - {hardware.services.name}{hardware.serviceQuantity === 1 ? '' : "'s"}
                            </StyledTypography>
                            <StyledTypography>
                              ${Math.round(hardware.serviceCost).toLocaleString()}
                            </StyledTypography>
                          </TextFieldsWrapper>
                        </>
                      )}
                      <TextFieldsWrapper>
                        <StyledTypography>
                          Total Price Before Incentives
                        </StyledTypography>
                        <StyledTypography>
                          ${Math.round(hardware.totalCost).toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                      <TextFieldsWrapper>
                        <StyledTypography>
                          Federal Tax Credit (30%)
                        </StyledTypography>
                        <StyledTypography>
                          -${Math.round(hardware.totalCost * 0.3).toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                      <TextFieldsWrapper>
                        <StyledTypography header blue>
                          Price After Incentives
                        </StyledTypography>
                        <StyledTypography header blue>
                          ${Math.round(hardware.totalCost - hardware.totalCost * 0.3).toLocaleString()}
                        </StyledTypography>
                      </TextFieldsWrapper>
                    </FinanceFieldsWrapper>
                    <TextWrapper>
                      <StyledTypography2>
                      Installation fees are covered, but some additional work may be needed, such as upgrading the electrical main panel or installing hidden conduit. Lunex Direct cannot guarantee these costs or your qualification for rebates and incentives. Be sure to consult a tax professional to confirm your eligibility.
                      </StyledTypography2>
                    </TextWrapper>
                    <br />
                    <TextWrapper>
                      <StyledTypography2>
                      A Federal Investment Tax Credit (ITC) is typically available for those who purchase solar systems. Eligible expenses may include solar paired with storage batteries, such as Powerwall or other systems, and EV chargers. To claim the tax credit, your tax liability must meet or exceed the credit amount. Lunex Direct does not guarantee eligibility or the amount of any incentives. Please consult a tax professional for specific details regarding your eligibility.                  
                      </StyledTypography2>
                    </TextWrapper>
                    <br />
                    <TextWrapper>
                      <StyledTypography2>
                      I authorize Lunex Direct to reach out to me regarding this inquiry, as well as to provide additional information about Lunex products and services using the contact details I've provided. I acknowledge that calls or texts may utilize automated or computer-assisted dialing systems, or pre-recorded messages. Standard message and data rates may apply.                
                      </StyledTypography2>
                    </TextWrapper>
                  </>
                }
                {!success &&
                  <ButtonWrapper>
                    {submitting ? <CircularProgress size={24}/> : <StyledButton onClick={handleSignUpButton}>Sign Up Now</StyledButton>}
                  </ButtonWrapper>
                }
                {(success || error) &&
                  <SubmitMessageWrapper>
                    {success && <SubmitMessageText success>{success}</SubmitMessageText>}
                    {error && <SubmitMessageText>{error}</SubmitMessageText>}
                  </SubmitMessageWrapper>
                }
                
                
              </Column>
            </>
          )}
        </ContentWrapper>
      </PopupContainer>
    </>
  );
};

export default PricingPopup;
import React from 'react'
import { 
  ImageBg, 
  LoginLink, 
  LunexHeaderLogo, 
  NavbarLogout, 
  ProfileWrapper, 
  QuoteBg, 
  QuoteInnerContainer, 
  QuoteOuterContainer, 
  TopBar 
} from './QuoteElements'
import logo from '../../images/lunexdirectlogo.jpg';
import { Link, useNavigate } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import Chat from '../Chat/Chat';
import lunex from '../../images/LunexPower.webp';
import FontStyles from '../../fontStyles';

const MainOutline = ({
  showLogout,
  backgroundImage,
  children
}) => {
  // const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
      await logout();
      // navigate('/login');
  }

  const SketchfabEmbed = () => {
    return (
      <div className="sketchfab-embed-wrapper" style={{ width: '100%', height: '480px' }}>
        <iframe
          title="SOLAR_HOUSE_2"
          frameBorder="0"
          allowFullScreen
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          allow="autoplay; fullscreen; xr-spatial-tracking"
          src="https://sketchfab.com/models/b330bfae39414462b4023bbce5d7b3e3/embed"
          style={{ width: '100%', height: '100%' }}
        ></iframe>
        {/* <p style={{ fontSize: '13px', fontWeight: 'normal', margin: '5px', color: '#4A4A4A' }}>
          <a
            href="https://sketchfab.com/3d-models/solar-house-2-b330bfae39414462b4023bbce5d7b3e3?utm_medium=embed&utm_campaign=share-popup&utm_content=b330bfae39414462b4023bbce5d7b3e3"
            target="_blank"
            rel="nofollow"
            style={{ fontWeight: 'bold', color: '#1CAAD9' }}
          >
            SOLAR_HOUSE_2
          </a>{' '}
          by{' '}
          <a
            href="https://sketchfab.com/proteinsimulation?utm_medium=embed&utm_campaign=share-popup&utm_content=b330bfae39414462b4023bbce5d7b3e3"
            target="_blank"
            rel="nofollow"
            style={{ fontWeight: 'bold', color: '#1CAAD9' }}
          >
            Interactive 3D Data
          </a>{' '}
          on{' '}
          <a
            href="https://sketchfab.com?utm_medium=embed&utm_campaign=share-popup&utm_content=b330bfae39414462b4023bbce5d7b3e3"
            target="_blank"
            rel="nofollow"
            style={{ fontWeight: 'bold', color: '#1CAAD9' }}
          >
            Sketchfab
          </a>
        </p> */}
      </div>
    );
  };

  return (
    <>
    <FontStyles />

    <QuoteOuterContainer>
        <TopBar>
          <Link to='/'>
            <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'}/>
          </Link>
            {/* <ProfileWrapper>
              {showLogout &&
                <Link to='/login' style={{textDecoration:'none'}}>
                  <LoginLink onClick={signOut}>Logout</LoginLink>
                </Link>
              }
              {!showLogout &&
                <Link to='/login' style={{textDecoration:'none'}}>
                  <LoginLink>Login</LoginLink>
                </Link> 
              }
                           
            </ProfileWrapper> */}

          
        </TopBar>
        {/* <QuoteOuterWrapper></QuoteOuterWrapper> */}
        {/* <QuoteBg>
            <ImageBg src={lunex} alt={'Lunex Direct'}/>
        </QuoteBg> */}
        <QuoteInnerContainer>
          {children}
        </QuoteInnerContainer>
        
    </QuoteOuterContainer>
    {/* <Chat /> */}
    </>
  )
}

export default MainOutline
import { useState, useEffect } from 'react';

const useAutoDesigner = ({
    hardware,
    updateHardware,
    responseState,
    formState,
    setDesignLocked,
}) => {
    const [designStatus, setDesignStatus] = useState('pending');
    const [submitted, setSubmitted] = useState(false);
    const [designInfo, setDesignInfo] = useState(null);
    const [isPolling, setIsPolling] = useState(true);

    // Function to start the design automation process
    const startAutomation = async () => {
        setDesignLocked(true);
        let inverterName;
        if(hardware.inverter.name === 'Tesla Inverter' && hardware.battery.name === 'Tesla Powerwall 3' && hardware.batteryQuantity > 0){
            inverterName = 'Tesla Powerwall 3';
        }
        else{
            inverterName = hardware.inverter.name;
        }
        
        setSubmitted(true);

        const body = {
            projectId: responseState.projectId,
            designId: responseState.designId,
            customerName: formState.name,
            inverter: inverterName,
        };

        const annualProduction = responseState.solarProductionMonthly*12;
        if(annualProduction > 30000){
            body.targetOffset = ((30000 / annualProduction) * 100).toFixed(2);
        }
        else{
            body.targetOffset = hardware.offset.toFixed(1);
        }

        try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/estimate/run-automation', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        const result = await response.json();
        console.log(result);
        } catch (error) {
        console.error('Error:', error);
        }
    };

    // Function to fetch the design status
    const fetchDesignStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/estimate/getCustomerDesignStatus/${responseState.projectId}`);
            const result = await response.json();

            // Update design info and status
            updateHardware('designInfo', result);

            switch (result.designStatus) {
                case 'initial':
                    setDesignStatus('initial');
                    updateHardware('designImage', result.initial);
                    updateHardware('designText', "Determining Roof Dimensions...");
                    break;
                case 'roof':
                    setDesignStatus('roof');
                    updateHardware('designImage', result.roof);
                    updateHardware('designText', "Using AI to Design Your System...");
                    break;
                case 'design':
                    setDesignStatus('design');
                    updateHardware('designImage', result.design);
                    updateHardware('designText', "Finalizing System...");
                    break;
                case 'lidar':
                    setDesignStatus('lidar');
                    updateHardware('designImage', result.lidar);
                    updateHardware('designText', "Creating Irradiance Map...");
                    break;
                case 'irradiance':
                    setDesignStatus('irradiance');
                    updateHardware('designImage', result.irradiance);
                    updateHardware('designText', "Finalizing System...");
                    break;
                
                case 'completed':
                    setDesignStatus('completed');
                    updateHardware('systemSize', result.systemSize);
                    updateHardware('offset', parseInt(result.energyOffset.replace('%', ''), 10));
                    updateHardware('designImage', result.screenshot3d);
                    setDesignInfo(result);
                    const sumOfProduction = result.monthlyProduction
                        .map(value => parseFloat(value))  // Convert each string to a decimal number
                        .reduce((acc, curr) => acc + curr, 0);
                    updateHardware('annualProduction', sumOfProduction);
                    break;
                case 'incompatible':
                    setDesignStatus('incompatible');
                    updateHardware('designText', "We were not able to get up to date imaging for your home.");
                    break;
                case 'failed':
                    setDesignStatus('failed');
                    updateHardware('designText', "We encountered an error, trying to create design again.");
                    break;
                case 'failedFinal':
                    setDesignStatus('failedFinal');
                    updateHardware('designText', "We were not able to complete your design. Please contact us at (813)640-8803");
                    break;
                default:
                    setDesignStatus('loading');
                    updateHardware('designText', "Loading Home...");
            }
        } catch (error) {
        console.error('Error fetching design status:', error);
        }
    };

    // Poll the design status every 5 seconds if design is not completed
    useEffect(() => {
        let intervalId;
        let timeoutId;

        console.log("design status ", designStatus);

        if (submitted && isPolling && designStatus !== 'completed' && designStatus !== 'incompatible' && designStatus !== 'failedFinal') {
            // Add a delay of 10 seconds before starting the interval
            timeoutId = setTimeout(() => {
                intervalId = setInterval(() => {
                    fetchDesignStatus();
                }, 5000); // Poll every 5 seconds
            }, 10000); // Delay the first fetch by 10 seconds
        }

        // Cleanup function: clear the timeout and interval
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [designStatus, submitted, responseState.projectId, isPolling]);

    const stopPolling = () => {
        setIsPolling(false);
    };

    return {
        designStatus,
        designInfo,
        startAutomation,
        stopPolling
    };
};

export default useAutoDesigner;
